var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 mr-3",staticStyle:{"width":"99%"}},[_c('a-list',{staticClass:"comment-list",attrs:{"header":((_vm.handleInfoList.length) + " 条审批意见"),"item-layout":"horizontal","data-source":_vm.handleInfoList},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{staticClass:"w-full relative"},[(item.showFlag)?_c('span',{staticClass:"replay-msg"},[_vm._v("回复成功")]):_vm._e(),_c('a-comment',{attrs:{"author":item.handleman}},[_c('template',{slot:"avatar"},[_c('a-avatar',{style:({ backgroundColor: '#1D6FA3' }),attrs:{"size":"large"}},[_c('span',{domProps:{"textContent":_vm._s(
                item.handleman.length < 4
                  ? item.handleman[0]
                  : item.handleman.slice(0, 2)
              )}})])],1),(!(item.showSubComment === true))?_c('template',{slot:"actions"},[_c('span',{on:{"click":function($event){return _vm.replay(item)}}},[_c('a-icon',{staticClass:"mr-1",attrs:{"type":"rollback"}}),_vm._v(" 回复 ")],1)]):_vm._e(),_c('p',{attrs:{"slot":"content"},slot:"content"},[(!item.vnote)?_c('span',[_vm._v(" "+_vm._s(item.attitude == _vm.attitudeEnum.AGREE ? '同意' : item.attitude == _vm.attitudeEnum.RECAPTION ? '取回' : '')+" ")]):[_c('p',{domProps:{"innerHTML":_vm._s(item.vnote)}})]],2),_c('template',{slot:"datetime"},[_c('span',[_vm._v(_vm._s(item.ts))]),_c('span',{staticClass:"ml-2"},[_c('a-tag',{attrs:{"color":_vm.background(item.attitude)}},[_vm._v(" "+_vm._s(_vm.getAttitude(item.attitude))+" ")])],1)]),(item.showSubComment)?_c('a-comment',[_c('a-avatar',{attrs:{"slot":"avatar","size":"large"},slot:"avatar"},[_vm._v(" "+_vm._s(_vm.$store.getters.username[0])+" ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('a-form-item',[_c('a-textarea',{attrs:{"rows":4},model:{value:(_vm.vnote),callback:function ($$v) {_vm.vnote=$$v},expression:"vnote"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"html-type":"submit","type":"primary"},on:{"click":function($event){return _vm.handleReplyClick(item)}}},[_vm._v(" 回复 ")]),_c('a-button',{staticClass:"ml-2",on:{"click":function () { return (item.showSubComment = false); }}},[_vm._v(" 取消 ")]),_c('a-button',{staticStyle:{"float":"right"},attrs:{"type":"link"},on:{"click":_vm.upload}},[_c('a-icon',{attrs:{"type":"paper-clip"}}),_vm._v(" 上传附件("+_vm._s(_vm.fileList.length)+") ")],1)],1)],1)],1):_vm._e(),(item.subApproveInfos && item.subApproveInfos.length)?_c('span',_vm._l((item.subApproveInfos),function(subItem){return _c('a-comment',{key:subItem.pkApproveinfo,attrs:{"author":subItem.handleman}},[_c('template',{slot:"avatar"},[_c('a-avatar',{style:({ backgroundColor: '#1D6FA3' }),attrs:{"size":"large"}},[_c('span',{domProps:{"textContent":_vm._s(
                    subItem.handleman.length < 4
                      ? subItem.handleman[0]
                      : subItem.handleman.slice(0, 2)
                  )}})])],1),(subItem.attitude == _vm.attitudeEnum.REPLY)?_c('template',{slot:"actions"},[_c('span',{on:{"click":function($event){return _vm.preview(subItem)}}},[_c('a-icon',{staticClass:"mr-1",attrs:{"type":"paper-clip"}}),_vm._v(" "+_vm._s(("附件信息 (" + (_vm.isXSBX ? subItem.approveInfoBillFiles ? subItem.approveInfoBillFiles.length : 0 : subItem.approveInfoBillFilesForOtherBill ? subItem.approveInfoBillFilesForOtherBill.length : 0) + ")"))+" ")],1)]):_vm._e(),_c('p',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(subItem.vnote)+" ")]),_c('template',{slot:"datetime"},[_c('span',[_vm._v(_vm._s(subItem.ts))]),_c('span',{staticClass:"ml-2"},[_c('a-tag',{attrs:{"color":_vm.background(subItem.attitude)}},[_vm._v(" "+_vm._s(_vm.getAttitude(subItem.attitude))+" ")])],1)])],2)}),1):_vm._e()],2)],1)}}])}),(_vm.showPayUpload)?_c('pay-upload-modal',{attrs:{"uploadName":"回复","pid":_vm.billId,"uploadFilelistInfo":_vm.uploadFilelistInfo},on:{"close":function($event){_vm.showPayUpload = false},"hasUpload":function($event){return _vm.hasUpload($event)}}}):_vm._e(),(_vm.showPayPreview)?_c('pay-preview-modal',{attrs:{"uploadName":"回复","previewFileListInfo":_vm.previewFileListInfo},on:{"close":function($event){_vm.showPayPreview = false}}}):_vm._e(),(_vm.showUpload)?_c('upload-modal',{attrs:{"uploadName":"replay","uploadFilelistInfo":_vm.uploadFilelistInfo},on:{"close":function($event){_vm.showUpload = false},"hasUpload":function($event){return _vm.hasUpload($event)}}}):_vm._e(),(_vm.showPreview)?_c('preview-modal',{attrs:{"uploadName":"replay","previewFileListInfo":_vm.previewFileListInfo},on:{"close":function($event){_vm.showPreview = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
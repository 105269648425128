import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import BasicLayout from '@/layouts/BasicLayout'
import Workplace from '@/views/workplace'
import exception from './modules/exception'
import customer from './modules/customer'
import product from './modules/product'
import activity from './modules/activity'
import query from './modules/query'
import sale from './modules/sale'
import password from './modules/password'
import user from './modules/user'
import menu from './modules/menu'
import flowdirect from './modules/flowdirect'
import fee from './modules/fee'
import outer from './modules/outer'
import generic from './modules/generic'
import house from './modules/house'
import performance from './modules/performance'
import { logout, logged, getUserMsg } from '@/utils'
import { ACTIVITY_DATE } from '@/configs/date-config'
import dayjs from '@/utils/dayjs'

Vue.use(Router)

export const routerList = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/Login')
  },
  {
    path: '/',
    name: 'basicLayout',
    component: BasicLayout,
    redirect: '/workplace',
    children: [
      {
        path: 'workplace',
        name: 'workplace',
        component: Workplace,
        meta: { title: '首页', affix: true, menuKey: 'workplace' }
      },
      {
        path: 'morenotice',
        name: 'morenotice',
        component: () =>
          import(
            /* webpackChunkName: 'MoreNotice' */ '@/views/hiddenpage/MoreNotice'
          ),
        meta: { title: '更多消息' }
      },
      {
        path: 'auditbill',
        name: 'auditbill',
        component: () =>
          import(
            /* webpackChunkName: 'auditbill' */ '@/views/workplace/work/AuditBill'
          )
      },
      user,
      customer,
      product,
      password,
      menu,
      activity,
      query,
      exception,
      sale,
      fee,
      flowdirect,
      outer,
      generic,
      house,
      performance
    ]
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routerList
})

router.beforeEach((to, from, next) => {
  if (to.path === '/redirect') {
    let { token, route, uid, ...params } = to.query
    if (!token || !route) {
      const error = new Error('请传入 token 或 route')
      error.name = 404
      next(error)
    }

    if (params.appDate) {
      if (dayjs(ACTIVITY_DATE).isBefore(params.appDate)) {
        if (route === 'academicApplicationForm') route = 'appForm'
        if (route === 'activityReimbursementForm') route = 'exReimForm'
      }
    }

    if (params.needProxyApprove === 'true') {
      params.proxyApproveRelDto = JSON.parse(params.proxyApproveRelDto || '{}')
    } else if (params.needProxyApprove === 'false') {
      params.proxyApproveRelDto =
        params.proxyApproveRelDto === '""'
          ? null
          : params.proxyApproveRelDto || null
    }

    store.commit('user/SET_USER', { uid, token })
    getUserMsg(route, params)
    return
  }
  if (to.path === '/logged') {
    const { token } = to.query
    if (!token) {
      const error = new Error('请传入 token')
      error.name = 404
      next(error)
    }
    store.commit('user/SET_USER', { token })
    logged()
    return
  }
  if (to.path === '/bill') {
    let { token, route, uid, ...params } = to.query
    if (!token || !route) {
      const error = new Error('请传入 token 或 route')
      error.name = 404
      next(error)
    }

    if (params.appDate) {
      if (dayjs(ACTIVITY_DATE).isBefore(params.appDate)) {
        if (route === 'academicApplicationForm') route = 'appForm'
        if (route === 'activityReimbursementForm') route = 'exReimForm'
      }
    }

    if (params.needProxyApprove === 'true') {
      params.proxyApproveRelDto = JSON.parse(params.proxyApproveRelDto || '{}')
    } else if (params.needProxyApprove === 'false') {
      params.proxyApproveRelDto = params.proxyApproveRelDto || null
    }

    store.commit('user/SET_USER', { uid, token })
    getUserMsg(route, params)
    return
  }
  if (!to.matched.length) {
    const error = new Error('指定的路由不存在')
    error.name = 404
    if (from.name === '404') return
    next(error)
  }
  const isLoginView = to.name === 'login'
  if (!isLoginView && !store.getters['token']) {
    logout()
    return
  }
  next(true)
})

router.onError(error => {
  if (error.name === 404) {
    // const fullpath = `${location.origin}/exception/404`
    // location.href = fullpath
    router.push({
      name: '404'
    })
  }
})

export default router

<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">药连锁办事处用房租赁申请详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :disabled="isFlow"
        :loading="dataLoading"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'housePropertyArchiveModify' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
        @select-change="onUserChange"
      ></base-form-items>

      <base-form-title title="原住房基本信息" icon="home" />
      <base-form-items
        :formData="form"
        :formBaseList="formOldBaseList"
      ></base-form-items>

      <base-form-title title="房产档案修改信息" icon="home" />

      <template v-if="type !== 'waitwork'">
        <base-form-items
          :formData="form"
          :formBaseList="formBaseList"
          :formFileList="formBaseFileList"
          :formTextAreaList="formBaseTextAreaList"
          @open-modal="openModal"
          @open-file="openFile"
        ></base-form-items>
      </template>
      <template v-else>
        <base-form-modify-item
          :formData="form"
          :formBaseList="formBaseList"
          :formFileList="formBaseFileList"
          :formTextAreaList="formBaseTextAreaList"
          @open-modal="openModal"
          @open-file="openFile"
        ></base-form-modify-item>
      </template>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDO"
        :status="isFlow"
        :summaryid="form.flowInfoDO.summaryid"
        :nextapproveman="form.flowInfoDO.nextapproveman"
        :curApprover="form.flowInfoDO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicant" :billId="form.id"></comment>
    </base-form>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <DeptmentModal
      v-if="showModal"
      :visible="showModal"
      @choose-dept="chooseDept"
      @close="showModal = false"
    ></DeptmentModal>

    <!-- <HouseArchivesModal
      v-if="showArchivesModal"
      :visible="showArchivesModal"
      :isAll="true"
      @choose="chooseHouseArchives"
      @close="showArchivesModal = false"
    ></HouseArchivesModal> -->
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import BaseFormModifyItem from '@/components/FieldSet/BaseFormModifyItem'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import DeptmentModal from '@/components/Modal/DeptmentModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
// import HouseArchivesModal from '@/components/Modal/HouseArchivesModal'
// api
import moment from 'moment'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { getDoc } from '@/configs/doc-config'
import {
  saveHouseModify,
  updateHouseModify,
  delHouseModify,
  getHouseModify,
  submitHouseModify
} from '@/api/house/modify-house'
import { getArchiveHouse } from '@/api/house/archive-house'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import {
  getOACancel2,
  getDeptByUid
} from '@/api/acdemicActive/acdemic-application'

// mixin
import userMixin from '../mixins/user-mixin'
import verityMixin from '../mixins/verity-mixin'

export default {
  name: 'houseModifyForm',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    BaseFormModifyItem,
    UploadModal,
    PreviewModal,
    DeptmentModal,
    Process,
    Comment
    // HouseArchivesModal
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,
      showModal: false,
      // showArchivesModal: false,
      useTypeEmun: [], // 使用类型枚举

      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      dataLoading: false, // 提交数据

      /* eslint-disable prettier/prettier */
      form: {
        appdate: '', // 申请日期
        applicant: '', // 申请人id
        applicantName: '', // 申请人名称
        post: '', //岗位
        contact: '', // 联系方式
        region: '', // 大区id
        regionName: '', // 大区名称
        province: '', // 省办id
        provinceName: '', // 省办名称
        locality: '', // 地办id
        localityName: '', // 地办名称
        applicantDepartment: '', //部门id

        housePropertyArchiveId: '', // 房产档案id
        applicationCode: '', // 申请单号

        oldHouseCode: '', // 旧房产编码
        oldHouseStatusId: '', // 旧房产状态
        oldHouseStatusName: '', // 旧房产状态名称
        oldHouseTypeId: '', // 旧房产类型
        oldHouseTypeName: '', // 旧房产类型
        oldUseType: '', // 旧使用类型
        oldUseTypeName: '', // 旧使用类型名称
        oldAddress: '', // 旧详细地址
        oldFloorArea: '', // 旧面积
        oldHouseDeptId: '', // 旧所属管理部门
        oldHouseDeptName: '', // 旧所属管理部门
        oldHouseOwner: '', // 旧房屋产权所有人
        oldOperator: '', // 旧经办人
        oldTenant: '', // 旧承租方
        oldContractTerm: '', // 旧合同期限（月）
        oldContractStartDate: '', // 旧合同开始日期
        oldContractEndDate: '', // 旧合同结束日期
        oldRealRentalPrice: '', // 旧月租金
        oldCompanyRentalPrice: '', // 旧公司承担租金
        oldPersonRentalPrice: '', // 旧员工承担租金
        oldPayType: '', // 旧付款方式
        oldPayTypeName: '', // 旧付款方式名称

        houseCode: '', // 房产编码
        houseStatusId: '', // 房产状态
        houseStatusName: '', // 房产状名称
        houseTypeId: '', // 房产类型
        houseTypeName: '', // 房产类型名称
        useType: '', // 使用类型
        useTypeName: '', // 使用类型名称
        address: '', // 详细地址
        floorArea: '', // 面积
        houseDeptId: '', // 所属管理部门
        houseDeptName: '', // 所属管理部门
        houseOwner: '', // 房屋产权所有人
        operator: '', // 经办人
        tenant: '', // 承租方
        contractTerm: '', // 合同期限（月）
        contractStartDate: '', // 合同开始日期
        contractEndDate: '', // 合同结束日期
        realRentalPrice: '', // 月租金
        companyRentalPrice: '', // 公司承担租金
        personRentalPrice: '', // 员工承担租金
        payType: '', // 付款方式
        payTypeName: '', // 付款方式名称

        rentalContractId: '', // 租赁合同
        housePropertyId: '', // 房产证复印件
        houseOwnerIDCardId: '', // 房东身份证
        buyHouseContract: '', // 购房合同
        propertyCard: '',//产权证

        flowInfoDO: { status: '1' } // 流程信息
      },

      formOldBaseList: [
        { label: '房产地址', prop: 'address', disabled: true },
        { label: '房产编码', prop: 'oldHouseCode', disabled: true },
        { label: '房产状态', prop: 'oldHouseStatusName', disabled: true },
        { label: '房产类型', prop: 'oldHouseTypeName', disabled: true },
        { label: '使用类型', prop: 'oldUseTypeName', disabled: true  },
        { label: '面积（m²）', prop: 'oldFloorArea', disabled: true },
        { label: '所属管理部门', prop: 'oldHouseDeptName', disabled: true },
        { label: '房屋产权所有人', prop: 'oldHouseOwner', disabled: true },
        { label: '经办人', prop: 'oldOperator', disabled: true },
        { label: '承租方', prop: 'oldTenant', disabled: true },
        { label: '合同期限（月）', prop: 'oldContractTerm', disabled: true },
        { label: '合同开始日期', prop: 'oldContractStartDate', disabled: true },
        { label: '合同结束日期', prop: 'oldContractEndDate', disabled: true },
        { label: '月租金', prop: 'oldRealRentalPrice', disabled: true },
        { label: '公司承担租金（元/月）', show:() => !this.isOldWork, prop: 'oldCompanyRentalPrice', disabled: true },
        { label: '员工承担租金（元/月）',show:() => !this.isOldWork, prop: 'oldPersonRentalPrice', disabled: true },
        { label: '付款方式', prop: 'oldPayTypeName', disabled: true },
      ],

      formBaseList: [
        { label: '单据号', prop: 'applicationCode', disabled: true },
        { label: '变更类型', prop: 'modifyType', disabled: () => this.getCommonDisabled, type: 'select', options: [] },
        { label: '房产地址', prop: 'address', disabled: true },
        { label: '房产编码', prop: 'houseCode', disabled: true },
        { 
          label: '房产状态', 
          prop: 'houseStatusId', 
          disabled: () => (this.getCommonDisabled || this.isRenewalHouse || this.isBackHouse) || this.isBackHouse, 
          type: 'select', 
          options: [],
          originProp: 'oldHouseStatusName',
          curProp: 'houseStatusName',
        },
        { 
          label: '房产类型', 
          prop: 'houseTypeId', 
          disabled: true, 
          type: 'select', 
          options: [], 
          originProp: 'oldHouseTypeName', 
          curProp: 'houseTypeName' 
        },
        { 
          label: '使用类型', 
          prop: 'useType', 
          disabled: () => this.isFlow || !this.isUse, 
          type: 'select', 
          options: [], 
          originProp: 'oldUseTypeName', 
          curProp: 'useTypeName' 
        },
        { 
          label: '面积（m²）', 
          prop: 'floorArea', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          originProp: 'oldFloorArea', 
          curProp: 'floorArea' 
        },
        { 
          label: '所属管理部门', 
          prop: 'houseDeptName', 
          type: 'search', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          originProp: 'oldHouseDeptName', 
          curProp: 'houseDeptName' 
        },
        { 
          label: '房屋产权所有人', 
          prop: 'houseOwner', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          originProp: 'oldHouseOwner', 
          curProp: 'houseOwner' 
        },
        { 
          label: '经办人', 
          prop: 'operator', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          originProp: 'oldOperator', 
          curProp: 'operator' 
        },
        { 
          label: '承租人', 
          prop: 'tenant', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          originProp: 'oldTenant', 
          curProp: 'tenant' 
        },
        { 
          label: '合同期限（月）', 
          prop: 'contractTerm', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          originProp: 'oldContractTerm', 
          curProp: 'contractTerm'
        },
        { 
          label: '合同开始日期', 
          prop: 'contractStartDate', 
          type: 'date', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          disabledDate: () => this.disabledStDate,
          originProp: 'oldContractStartDate',
          curProp: 'contractStartDate'
        },
        { 
          label: '合同结束日期', 
          prop: 'contractEndDate',
          type: 'date', 
          disabled: () => this.getCommonDisabled,
          disabledDate: () => this.disabledEndDate,
          originProp: 'oldContractEndDate',
          curProp: 'contractEndDate'
        },
        { 
          label: '月租金', 
          prop: 'realRentalPrice', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          validate: 'digit', 
          originProp: 'oldRealRentalPrice', 
          curProp: 'realRentalPrice' },
        { 
          label: '公司承担租金（元/月）', 
          prop: 'companyRentalPrice', 
          show:() => !this.isWork, 
          disabled: true, 
          validate: 'digit',
          originProp: 'oldCompanyRentalPrice',
          curProp: 'companyRentalPrice'
        },
        { 
          label: '员工承担租金（元/月）', 
          prop: 'personRentalPrice', 
          show:() => !this.isWork, 
          disabled: true, 
          validate: 'digit',
          originProp: 'oldPersonRentalPrice',
          curProp: 'personRentalPrice'
        },
        { 
          label: '付款方式', 
          prop: 'payType', 
          disabled: () => this.getCommonDisabled || this.isBackHouse, 
          type: 'select', 
          options: [],
          originProp: 'oldPayTypeName',
          curProp: 'payTypeName'
        },
      ],

      formBaseFileList: [
        { label: '租赁合同', notVerity: true, prop: 'rentalContractId' },
        { label: '房产证复印件', notVerity: true, prop: 'housePropertyId' },
        { label: '房东身份证', notVerity: true, prop: 'houseOwnerIDCardId' },
        { label: '购房合同', notVerity: true, prop: 'buyHouseContract' },
        { label: '产权证', notVerity: true, prop: 'propertyCard' },
      ],

      formBaseTextAreaList: [
        { label: '说明', prop: 'note', disabled: () => this.getCommonDisabled || this.isBackHouse },
      ],
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow
    },

    // 是否续租
    isRenewalHouse() {
      if(this.formBaseList[1].options && this.formBaseList[1].options.length < 1) {
        return false
      }

      return this.formBaseList[1].options.find(item => item.text === '续租').value === this.form.modifyType
    },

    // 是否退租
    isBackHouse() {
      if(this.formBaseList[1].options && this.formBaseList[1].options.length < 1) {
        return false
      }

      return this.formBaseList[1].options.find(item => item.text === '退租').value === this.form.modifyType
    },

    // 是否在用
    isUse() {
      if(this.formBaseList[4].options && this.formBaseList[4].options.length < 1) {
        return false
      }

      return this.formBaseList[4].options.find(item => item.text === '在用').value === this.form.houseStatusId
    },

    isOldWork() {
      return this.form.oldUseTypeName === '办公'
    },

    isWork() {
      return this.form.useTypeName === '办公'
    }
  },

  created() {
    // 变更方式
    getDoc('069', res => (this.formBaseList[1].options = res))
    // 房产状态
    getDoc('063', res => (this.formBaseList[4].options = res))
    // 房产类型
    getDoc('064', res => (this.formBaseList[5].options = res))
    // 使用类型
    getDoc('061', res => { 
      this.formBaseList[6].options = res
      this.useTypeEmun = res
    })
    // 付款方式
    getDoc('062', res => (this.formBaseList[18].options = res))

    if(this.curType !== 'add') {
      getHouseModify(this.curPid).then(res => {
        // 申请人部门列表
        getDeptByUid(res.applicant).then(depts => {
          this.formUserList[7].options = this.changeDeptProps(depts)
        })

        this.form = res
        // 单据状态(向上通知)
        const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
        this.$emit('status', status)
      })
    } else {
      getArchiveHouse(this.curPid).then(res => {
        this.initData(res)
      })
    }
  },

  watch: {
    'form.useType'(nval) {
      const target = this.useTypeEmun.find(i => i.value === nval)

      if (!target) return 

      if(target.text === '办公') {
        this.form.companyRentalPrice = ''
        this.form.personRentalPrice = ''
      }

      this.form.useTypeName = target.text
    },

    'form.realRentalPrice'(nval) {
      this.form.companyRentalPrice = this.$np.round(this.$np.times(nval || "0", 0.8), 2)
      this.form.personRentalPrice = this.$np.round(this.$np.times(nval || "0", 0.2), 2)
    },

    // 续租，房产状态固定在用
    isRenewalHouse(nval) {
      if (nval) {
        this.form.houseStatusId = this.formBaseList[4].options.find(item => item.text === '在用').value
      }
    },
    // 退租，房产状态固定停用
    isBackHouse(nval) {
      if (nval) {
        this.form.houseStatusId = this.formBaseList[4].options.find(item => item.text === '停用').value
      }
    }
  },

  methods: {
    moment,

    initData(data) {
      const props = [
      'houseCode', // 房产编码
      'houseStatusId', // 房产状态
      'houseStatusName', // 房产状名称
      'houseTypeId', // 房产类型
      'houseTypeName', // 房产类型名称
      'useType', // 使用类型
      'useTypeName', // 使用类型名称
      'address', // 详细地址
      'floorArea', // 面积
      'houseDeptId', // 所属管理部门
      'houseDeptName', // 所属管理部门
      'houseOwner', // 房屋产权所有人
      'operator', // 经办人
      'tenant', // 承租方
      'contractTerm', // 合同期限（月）
      'contractStartDate', // 合同开始日期
      'contractEndDate', // 合同结束日期
      'realRentalPrice', // 月租金
      'companyRentalPrice', // 公司承担租金
      'personRentalPrice', // 员工承担租金
      'payType', // 付款方式
      'payTypeName', // 付款方式名称
      ]

      this.form.housePropertyArchiveId = data.id

      for (const prop of props) {
        const firstChar = prop[0]
        const oldProp = `old${firstChar.toLocaleUpperCase() + prop.slice(1)}`
        this.form[prop] = this.form[oldProp] = data[prop]
      }

    },

    chooseDept(item) {
      this.form.houseDeptId = item.did 
      this.form.houseDeptName = item.deptname 
    },

    chooseHouseArchives(item) {
      this.form.housePropertyArchiveId = item.id // 申请住房Id
      this.form.address = item.address // 申请住房
      this.form.houseCode = item.houseCode // 房产编号
      this.form.houseStatusId = item.houseStatusId // 房产状态
      this.form.houseStatusName = item.houseStatusName // 房产状态名称
      this.form.houseTypeId = item.houseTypeId // 房产类型
      this.form.houseTypeName = item.houseTypeName // 房产类型名称
      this.form.useType = item.useType // 使用类型
      this.form.useTypeName = item.useTypeName // 使用类型名称
      this.form.floorArea = item.floorArea // 使用面积
      this.form.houseDeptId = item.houseDeptId // 所属管理部门
      this.form.houseDeptName = item.houseDeptName // 所属管理部门名称
      this.form.houseOwner = item.houseOwner // 房屋产权所有人
      this.form.operator = item.operator // 经办人
      this.form.tenant = item.tenant // 承租方
      this.form.contractTerm = item.contractTerm // 合同期限（月）
      this.form.contractStartDate = item.contractStartDate // 合同开始日期
      this.form.contractEndDate = item.contractEndDate // 合同结束日期
      this.form.realRentalPrice = item.realRentalPrice // 月租金
      this.form.companyRentalPrice = item.companyRentalPrice // 公司承担租金
      this.form.personRentalPrice = item.personRentalPrice // 员工承担租金
      this.form.payType = item.payType // 付款方式
      this.form.payTypeName = item.payTypeName // 付款方式名称
    },

    openModal(item) {
      switch (item.prop) {
        case 'houseDeptName':
          this.showModal = true
          break
        // case 'houseCode':
        //   this.showArchivesModal = true
        //   break
        default:
          break
      }
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop

      if (this.isFlow || this.isBackHouse) {
        this.previewTitle = title
        this.previewFileListInfo = this.form[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = this.form[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '租赁合同':
          this.form.rentalContractId = fileListInfo
          break
        case '房产证复印件':
          this.form.housePropertyId = fileListInfo
          break
        case '房东身份证':
          this.form.houseOwnerIDCardId = fileListInfo
          break
        case '购房合同':
          this.form.buyHouseContract = fileListInfo
          break
        case '产权证':
          this.form.propertyCard = fileListInfo
          break
        default:
          break
      }
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
     disabledStDate(time) {
      return (
        time && time >= moment(this.form.contractEndDate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.form.contractStartDate).add(-1, 'day').endOf('day')
      )
    },

    filterData() {
      const submitData = cloneDeep(this.form)
      return submitData
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateHouseModify : saveHouseModify

      const submitData = this.filterData()

      if (this.verityAppForm(this.form.id ? false : 'save')) {
        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.$router.replace({ name: 'housePropertyArchiveModify' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {
        this.dataLoading = true
        submitHouseModify(submitData).then(() => {
          this.delUploadFile()
          this.$message.success('提交成功')
          this.$router.replace({ name: 'housePropertyArchiveModify' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该药连锁房产续租/变更申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delHouseModify(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'housePropertyArchiveModify' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该药连锁房产续租/变更申请申请？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'FCBG',
            this.form.flowInfoDO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
</style>

<template>
  <a-modal
    :visible="visible"
    title="报销明细（多选）"
    width="800px"
    closable
    wrapClassName="multiple"
    :maskClosable="false"
    :bodyStyle="{ padding: '0' }"
    @ok="submit"
    @cancel="$emit('close')"
  >
    <div class="modal-content">
      <a-form layout="vertical">
        <a-form-item
          label="费用类型"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-select v-model="houseFeeType" style="width: 250px">
            <a-select-option
              v-for="item in feeTypeEmun"
              :value="item.value"
              :key="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="金额"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-input v-model="fee" style="width: 250px"></a-input>
        </a-form-item>
        <a-form-item
          label="年份"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-radio-group v-model="year">
            <a-radio :value="item" v-for="item in yearEmun" :key="item">
              {{ item }}
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item
          label="月份"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
        >
          <a-checkbox-group
            v-model="month"
            name="checkboxgroup"
            :options="monthEmun"
          />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
</template>

<script>
import { getDoc } from '@/configs/doc-config'
import { Drag, resetDrag } from '@/utils/directives/drag'
export default {
  name: 'MultipleChooseModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 12 },
        wrapperCol: { span: 24 }
      },

      year: '', // 年份
      yearEmun: [], // 年份枚举
      month: [], // 月份
      monthEmun: [
        { label: '01月', value: '01' },
        { label: '02月', value: '02' },
        { label: '03月', value: '03' },
        { label: '04月', value: '04' },
        { label: '05月', value: '05' },
        { label: '06月', value: '06' },
        { label: '07月', value: '07' },
        { label: '08月', value: '08' },
        { label: '09月', value: '09' },
        { label: '10月', value: '10' },
        { label: '11月', value: '11' },
        { label: '12月', value: '12' }
      ], // 月份枚举
      feeTypeEmun: [], // 费用类型枚举
      houseFeeType: '', // 费用类型
      fee: ''
    }
  },

  destroyed() {
    this.$nextTick(() => {
      resetDrag()
    })
  },

  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          Drag('.multiple')
        })
      }
    }
  },

  created() {
    // 费用类型
    getDoc('067', res => (this.feeTypeEmun = res))

    // 默认年份
    this.year = this.$date().format('YYYY')
    for (let index = 0; index < 5; index++) {
      this.yearEmun.push(this.$date().subtract(index, 'year').format('YYYY'))
    }
  },

  methods: {
    submit() {
      if (!this.houseFeeType) {
        this.$message.warning('请选择费用类型')
      }

      if (this.month.length === 0) {
        this.$message.error('请选择月份')
        return
      }

      const list = []
      for (const month of this.month) {
        list.push({
          month: `${this.year}-${month}`,
          fee: this.fee,
          feeType: this.houseFeeType,
          feeTypeName: this.feeTypeEmun.find(
            item => item.value === this.houseFeeType
          ).text
        })
      }

      this.$emit('submit', list)
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  height: 480px;
  padding: 24px;
}
</style>

import FeeManage from '@/views/fee'
export default {
  path: 'feeManage',
  name: 'fee',
  component: FeeManage,
  redirect: 'feeManage/costSummary',
  children: [
    {
      // 业务员费用补扣明细
      path: 'cost-deduct-compensation',
      name: 'costDeductCompensation',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/cost-deduct-compensation'
        ),
      meta: { title: '业务员费用补扣明细', menuKey: 'costDeductCompensation' }
    },
    {
      // 业务员费用补扣明细详情
      path: 'conpensation-detail',
      name: 'conpensationDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/cost-deduct-compensation/ConpensationDetail'
        ),
      meta: {
        title: '业务员费用补扣明细详情',
        menuKey: 'costDeductCompensation'
      }
    },
    {
      // 业务员费用汇总
      path: 'cost-summary',
      name: 'costSummary',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/cost-summary'
        ),
      meta: { title: '业务员费用汇总', menuKey: 'costSummary' }
    },
    {
      // 业务员费用汇总详情
      path: 'cost-summary-detail',
      name: 'costSummaryDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/cost-summary/CostSummayDetail'
        ),
      meta: { title: '业务员费用汇总详情', menuKey: 'costSummary' }
    },
    {
      // 省办费用申请
      path: 'local-fee-app',
      name: 'localFeeApp',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/local-fee-app'
        ),
      meta: { title: '省办费用申请', menuKey: 'localFeeApp' }
    },
    {
      // 省办费用申请详情
      path: 'local-fee-app-detail',
      name: 'localFeeAppDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/local-fee-app/LocalFeeAppDetail'
        ),
      meta: { title: '省办费用申请详情', menuKey: 'localFeeApp' }
    },
    {
      // 费用报表
      path: 'expense-report',
      name: 'expenseReport',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/expense-report'
        ),
      meta: { title: '费用报表', menuKey: 'expenseReport' }
    },
    {
      // 费用申请查询
      path: 'fee-app-query',
      name: 'feeAppQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/fee/fee-app-query'
        ),
      meta: { title: '费用申请查询', menuKey: 'feeAppQuery' }
    }
  ]
}

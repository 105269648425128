<template>
  <div class="p-4">
    <a-row>
      <template v-for="item in formBaseList">
        <a-col
          v-if="!item.show || item.show()"
          :xl="6"
          :xxl="4"
          :key="item.prop"
        >
          <a-form-item
            v-if="!item.show || item.show()"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <template #label>
              <span
                v-if="
                  !item.curProp ||
                  !item.originProp ||
                  formData[item.curProp] === formData[item.originProp]
                "
              >
                {{ item.label }}
              </span>
              <a-popover v-else>
                <template slot="content">
                  <p>
                    <a-tag color="#f50">原值:</a-tag>
                    <span>{{ formData[item.originProp] }}</span>
                  </p>
                </template>
                <span style="color: #ed8936">{{ item.label }}</span>
              </a-popover>
            </template>
            <a-input
              :value="formData[item.curProp || item.prop]"
              :disabled="true"
            ></a-input>
          </a-form-item>
        </a-col>
      </template>
    </a-row>

    <template v-if="formFileList && formFileList.length">
      <a-row>
        <template v-for="item in formFileList">
          <a-col
            v-if="!item.show || item.show()"
            :xl="6"
            :xxl="4"
            :key="item.prop"
          >
            <a-form-item v-if="!item.show || item.show()">
              <div class="flex items-center">
                <span class="relative">
                  <span :class="[item.notVerity ? '' : 'app-form-required']">
                    {{ item.label }} :
                  </span>
                  <span
                    class="absolute text-white cursor-pointer"
                    style="right: -30px"
                    @click="openFile(item)"
                  >
                    {{
                      formData[item.prop]
                        ? JSON.parse(formData[item.prop]).length
                        : '0'
                    }}
                  </span>
                </span>
                <icon-svg
                  icon="icon_wenjianjia3"
                  class="text-4xl ml-2 cursor-pointer"
                  @click="openFile(item)"
                />
              </div>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </template>

    <template v-if="formTextAreaList && formTextAreaList.length">
      <a-row>
        <a-col :span="24" v-for="item in formTextAreaList" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelTextAreaCol"
            :wrapper-col="wrapperTextAreaCol"
          >
            <a-textarea
              :value="formData[item.prop]"
              placeholder="800字内"
              :disabled="true"
              :maxLength="800"
              :autoSize="{ minRows: 1, maxRows: 6 }"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { oninput } from '@/utils/validate'

export default {
  name: 'BaseFormItems',
  props: {
    // 数据
    formData: {
      type: Object,
      default: () => ({})
    },
    formBaseList: {
      type: Array,
      default: () => []
    },
    formFileList: {
      type: Array,
      default: () => []
    },
    formTextAreaList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      oninput,
      labelCol: { span: 20 },
      wrapperCol: { span: 20 },
      labelTextAreaCol: { span: 20 },
      wrapperTextAreaCol: { span: 24 }
    }
  },

  methods: {
    moment,

    openFile(item) {
      this.$emit('open-file', item)
    }
  }
}
</script>

<style lang="less" scoped></style>

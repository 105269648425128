import House from '@/views/house'
export default {
  path: 'house',
  name: 'house',
  component: House,
  redirect: 'house/danan',
  children: [
    {
      // 房产管理档案
      path: 'housePropertyArchive',
      name: 'housePropertyArchive',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseArchives'
        ),
      meta: { title: '药连锁房产档案', menuKey: 'housePropertyArchive' }
    },
    {
      // 房产管理档案
      path: 'houseArchivsForm',
      name: 'houseArchivsForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseArchives/HouseArchivsForm'
        ),
      meta: { title: '药连锁房产档案详情', menuKey: 'housePropertyArchive' }
    },

    {
      // 药连锁办事处用房租赁申请
      path: 'rentHouseApp',
      name: 'rentHouseApp',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/rentHouseApp'
        ),
      meta: { title: '药连锁办事处用房租赁申请', menuKey: 'rentHouseApp' }
    },
    {
      // 药连锁办事处用房租赁申请单
      path: 'rentHouseForm',
      name: 'rentHouseForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/rentHouseApp/RentHouseForm'
        ),
      meta: { title: '药连锁办事处用房租赁申请单', menuKey: 'rentHouseApp' }
    },

    {
      // 药连锁自由房产登记
      path: 'houseRegister',
      name: 'houseRegister',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/registerHouse'
        ),
      meta: { title: '药连锁自有房产登记', menuKey: 'houseRegister' }
    },
    {
      // 药连锁自由房产登记申请单
      path: 'registerHouseForm',
      name: 'registerHouseForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/registerHouse/RegisterHouseForm'
        ),
      meta: { title: '药连锁自有房产登记申请单', menuKey: 'houseRegister' }
    },

    {
      // 药连锁公司驻地宿舍申请
      path: 'houseDormitory',
      name: 'houseDormitory',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseDormitory'
        ),
      meta: { title: '药连锁公司驻地宿舍申请', menuKey: 'houseDormitory' }
    },
    {
      // 药连锁公司驻地宿舍申请申请单
      path: 'houseDormitoryForm',
      name: 'houseDormitoryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseDormitory/HouseDormitoryForm'
        ),
      meta: { title: '药连锁公司驻地宿舍申请详情', menuKey: 'houseDormitory' }
    },

    {
      // 药连锁房产续租/变更申请
      path: 'housePropertyArchiveModify',
      name: 'housePropertyArchiveModify',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseModify'
        ),
      meta: {
        title: '药连锁房产续租/变更申请',
        menuKey: 'housePropertyArchiveModify'
      }
    },
    {
      // 药连锁房产续租/变更申请单详情
      path: 'houseModifyForm',
      name: 'houseModifyForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseModify/houseModifyForm'
        ),
      meta: {
        title: '药连锁房产续租/变更申请详情',
        menuKey: 'housePropertyArchiveModify'
      }
    },

    {
      // 药连锁宿舍房租计算
      path: 'houseRentFeeCalc',
      name: 'houseRentFeeCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseRentFeeCalc'
        ),
      meta: { title: '药连锁宿舍房租计提', menuKey: 'houseRentFeeCalc' }
    },
    {
      // 药连锁宿舍房租计算详情
      path: 'houseFeeCalcForm',
      name: 'houseFeeCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/houseRentFeeCalc/HouseFeeCalcForm'
        ),
      meta: { title: '药连锁宿舍房租计提详情', menuKey: 'houseRentFeeCalc' }
    },

    {
      // 药连锁对公付款申请
      path: 'housePaymentDG',
      name: 'housePaymentDG',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/housePublicApp'
        ),
      meta: { title: '药连锁对公付款申请', menuKey: 'housePaymentDG' }
    },
    {
      // 药连锁对公付款申请
      path: 'pubilcAppForm',
      name: 'pubilcAppForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/housePublicApp/PubilcAppForm'
        ),
      meta: { title: '药连锁对公付款申请详情', menuKey: 'housePaymentDG' }
    },

    {
      // 药连锁对私付款申请
      path: 'housePaymentDS',
      name: 'housePaymentDS',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/housePrivateApp'
        ),
      meta: { title: '药连锁对私付款申请', menuKey: 'housePaymentDS' }
    },
    {
      // 药连锁对私付款申请
      path: 'privateAppForm',
      name: 'privateAppForm',
      component: () =>
        import(
          /* webpackChunkName: "group-house" */ '@/views/house/housePrivateApp/PrivateAppForm'
        ),
      meta: { title: '药连锁对私付款申请详情', menuKey: 'housePaymentDS' }
    }
  ]
}

<template>
  <div class="relative">
    <div class="example" v-if="loading">
      <loading-stuff title="申请单加载中..." class="loading-icon" />
    </div>

    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      type="flex"
      align="middle"
    >
      <a-col :span="12">
        <span v-if="mTimeStr" style="padding-left: 16px; color: #4587f5">
          <icon-font class="text-base" type="icon_huiyidengji"></icon-font>
          <span class="ml-2">{{ mTimeStr }}</span>
        </span>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-button type="primary" :disabled="isFlow" @click="save">
          保存
        </a-button>
        <a-button
          type="primary"
          class="ml-2"
          :disabled="isFlow"
          @click="submit"
        >
          提交
        </a-button>
        <a-button
          type="danger"
          class="ml-2"
          :disabled="isFlow || !form.id"
          @click="del"
        >
          删除
        </a-button>
        <a-button class="ml-2" :disabled="backDisabled" @click="getBack">
          撤销
        </a-button>
        <a-divider type="vertical"></a-divider>
        <a-button @click="showModal = true">打印申请单</a-button>
        <a-divider type="vertical"></a-divider>
        <a-button @click="$router.go(-1)">返回</a-button>
      </a-col>
    </a-row>

    <a-row v-else class="mt-4" type="flex" align="middle">
      <a-col :span="12">
        <span v-if="mTimeStr" style="padding-left: 16px; color: #4587f5">
          {{ mTimeStr }}
        </span>
      </a-col>
      <a-col :span="12" class="text-right">
        <a-button class="mr-4" @click="showModal = true">打印申请单</a-button>
      </a-col>
    </a-row>

    <a-form class="pl-4" labelAlign="left">
      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="user" />
        <span class="ml-2">用户信息</span>
      </div>
      <a-row class="p-4">
        <a-col :xl="6" :xxl="4" v-for="item in userForm" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :required="!item.disabled"
          >
            <a-select
              v-if="item.type === 'select'"
              v-model="user[item.prop]"
              :disabled="isFlow || item.disabled"
              @change="onUserChange($event, item.prop)"
            >
              <a-select-option
                v-for="doc in item.options"
                :key="doc.value"
                :value="doc.value"
              >
                {{ doc.text }}
              </a-select-option>
            </a-select>
            <a-input
              v-else
              v-model="user[item.prop]"
              :disabled="isFlow || item.disabled"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="gift" />
        <span class="ml-2">活动信息</span>
      </div>
      <a-row class="p-4">
        <template v-for="item in activityForm">
          <a-col
            v-if="!item.show || item.show()"
            :xl="6"
            :xxl="4"
            :key="item.prop"
          >
            <a-form-item
              v-if="!item.show || item.show()"
              :label="item.label"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              :required="!item.disabled"
            >
              <a-select
                v-if="item.type === 'select'"
                v-model="activity[item.prop]"
                :disabled="isFlow || item.disabled"
              >
                <a-select-option
                  v-for="doc in item.options"
                  :key="doc.value"
                  :value="doc.value"
                >
                  {{ doc.text }}
                </a-select-option>
              </a-select>
              <a-date-picker
                v-else-if="item.type === 'date'"
                class="w-full"
                format="YYYY-MM-DD"
                :value="
                  activity[item.prop] ? moment(activity[item.prop]) : null
                "
                :allowClear="false"
                :disabledDate="item.disabledDate()"
                :disabled="isFlow || item.disabled"
                @change="onDateChange(arguments, item)"
              />
              <a-input
                v-else-if="item.prop === 'activityPlace'"
                v-model="activity[item.prop]"
                :disabled="isFlow || item.disabled"
                :title="activity[item.prop]"
                readOnly
              >
                <a-icon
                  slot="suffix"
                  type="environment"
                  @click="
                    item => {
                      if (isFlow || item.disabled) return
                      showMap = true
                    }
                  "
                />
              </a-input>
              <a-input
                v-else
                v-model="activity[item.prop]"
                :disabled="isFlow || item.disabled"
                :title="activity[item.prop]"
                :oninput="item.validate ? oninput[item.validate] : ''"
              ></a-input>
            </a-form-item>
          </a-col>
        </template>
      </a-row>
      <a-row class="px-4">
        <template v-for="item in activityFileForm">
          <a-col
            v-if="!item.show || item.show()"
            :xl="6"
            :xxl="4"
            :key="item.prop"
          >
            <a-form-item v-if="!item.show || item.show()">
              <div class="flex items-center">
                <span class="relative">
                  <span :class="[item.notVerity ? '' : 'app-form-required']">
                    {{ item.label }} :
                  </span>
                  <span
                    class="absolute text-white cursor-pointer"
                    style="right: -30px"
                    @click="openFile(item)"
                  >
                    {{
                      activity[item.prop]
                        ? JSON.parse(activity[item.prop]).length
                        : '0'
                    }}
                  </span>
                </span>
                <icon-svg
                  icon="icon_wenjianjia3"
                  class="text-4xl ml-2 cursor-pointer"
                  @click="openFile(item)"
                />
              </div>
            </a-form-item>
          </a-col>
        </template>
      </a-row>

      <a-row class="p-4">
        <a-col :span="24" v-for="item in activityTextareaForm" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelTextAreaCol"
            :wrapper-col="wrapperTextAreaCol"
            :required="!item.disabled"
          >
            <a-textarea
              v-model="activity[item.prop]"
              placeholder="800字内"
              :disabled="isFlow || item.disabled"
              :maxLength="800"
              :autoSize="{ minRows: 1, maxRows: 6 }"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="schedule" />
        <span class="ml-2">活动类型及执行标准</span>
      </div>
      <a-row class="px-4 pt-4">
        <a-col v-for="item in standardForm" :xl="6" :xxl="4" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :required="!item.disabled"
          >
            <!-- 活动类型 -->
            <span v-if="item.prop === 'acttype'" class="relative">
              <a-input
                readOnly
                v-model="standard.activityTypeStdDTO[item.prop]"
                :title="standard.activityTypeStdDTO[item.prop]"
                :disabled="isFlow || item.disabled"
                @click="showActiveVisible"
              ></a-input>
              <a-avatar
                v-if="standard.activityTypeStdDTO[item.prop]"
                shape="square"
                class="absolute cursor-pointer"
                title="查看活动类型及执行标准详情"
                :size="18"
                :style="{
                  backgroundColor: '#fd875c',
                  verticalAlign: 'middle',
                  right: '-8px',
                  top: '-16px'
                }"
                @click.stop="
                  viewActivityTypeDetail(standard.activityTypeStdDTO)
                "
              >
                详
              </a-avatar>
            </span>
            <a-input
              v-else-if="
                item.type === 'between' &&
                (standard.activityTypeStdDTO[item.propGt] ||
                  standard.activityTypeStdDTO[item.propGt] === 0)
              "
              :value="`${standard.activityTypeStdDTO[item.propGt]} ~ ${
                standard.activityTypeStdDTO[item.propLt]
              }`"
              :title="standard.activityTypeStdDTO[item.prop]"
              :disabled="isFlow || item.disabled"
            ></a-input>
            <a-input
              v-else
              v-model="standard.activityTypeStdDTO[item.prop]"
              :title="standard.activityTypeStdDTO[item.prop]"
              :disabled="isFlow || item.disabled"
            ></a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="px-4">
        <a-col :span="24" v-for="item in standardTextareaForm" :key="item.prop">
          <a-form-item
            :label="item.label"
            :label-col="labelTextAreaCol"
            :wrapper-col="wrapperTextAreaCol"
          >
            <a-textarea
              v-model="standard.activityTypeStdDTO[item.prop]"
              placeholder="800字内"
              :title="standard.activityTypeStdDTO[item.prop]"
              :disabled="isFlow || item.disabled"
              :maxLength="800"
              :autoSize="{ minRows: 1, maxRows: 6 }"
            ></a-textarea>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="team" />
        <span class="ml-2">陪同人员信息</span>
        <a-popover trigger="hover">
          <template slot="content">
            <p class="p-2">
              1、活动信息-陪同人员人数应与陪同人员信息人数一致。
            </p>
          </template>
          <a-icon class="ml-2" type="exclamation-circle" />
        </a-popover>
      </div>
      <div class="p-4">
        <a-table
          bordered
          rowKey="personId"
          size="middle"
          :columns="accompanyColumns"
          :dataSource="accompany.appAccompanyingPersonsDtos"
          :pagination="false"
          :rowClassName="
            record => {
              return accompanyId === record.personId ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    accompanyId = record.personId
                  }
                }
              }
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="budgetTitle">
            <span class="app-form-required">陪同预算</span>
          </template>
          <!-- 陪同人员序号 -->
          <template slot="number" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>
          <!-- 陪同人员工号 -->
          <template slot="code" slot-scope="text, record, index">
            <span>
              {{ text }}
            </span>
            <a-icon
              v-if="!isFlow"
              type="search"
              class="cursor-pointer hover:text-blue-500 float-right mt-1"
              @click="showPersonVisible('update', index)"
            ></a-icon>
          </template>
          <!-- 陪同预算 -->
          <template slot="budget" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.accompanyBudget }}
            </span>
            <a-input
              v-else
              v-model="record.accompanyBudget"
              :oninput="oninput['digit']"
              @change="calcBudget"
            ></a-input>
          </template>
        </a-table>
        <a-button-group v-if="!isFlow">
          <a-button
            icon="plus"
            title="从最后一位陪同人员后增加一位陪同人员"
            @click="showPersonVisible('add')"
          >
            添加
          </a-button>
          <a-button
            icon="minus"
            type="danger"
            title="删除最后一位陪同人员"
            @click="delLastAccPerson"
          >
            删除
          </a-button>
        </a-button-group>
        <div class="mt-2 text-right">
          <span class="font-bold">陪同预算合计 :</span>
          <span class="ml-2 text-red-600">
            {{ accompany.accompanyExpense }}
          </span>
        </div>
      </div>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="usergroup-add" />
        <span class="ml-2">讲者信息</span>
        <a-popover trigger="hover">
          <template slot="content">
            <p class="p-2">
              1、讲者人数需要在选择的活动类型及执行标准范围内(总部项目名称某些枚举项除外)。
              <br />
              2、活动信息-讲者人数应与讲者信息人数一致。
            </p>
          </template>
          <a-icon class="ml-2" type="exclamation-circle" />
        </a-popover>
      </div>
      <div class="p-4">
        <a-table
          bordered
          rowKey="speakerId"
          size="middle"
          :columns="speakerColumns"
          :dataSource="speaker.speakers"
          :pagination="false"
          :rowClassName="
            record => {
              return speakerId === record.speakerId ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    speakerId = record.speakerId
                  }
                }
              }
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="feeTitle">
            <span class="app-form-required">讲者劳务费</span>
          </template>
          <template slot="topicTitle">
            <span class="app-form-required">讲者课题</span>
          </template>
          <template slot="lectureTitle">
            <span class="app-form-required">讲课时长(分钟)</span>
          </template>

          <!-- 讲者序号 -->
          <template slot="number" slot-scope="text, record, index">
            {{ index + 1 }}
          </template>

          <!-- 讲者姓名 -->
          <template slot="name" slot-scope="text, record, index">
            <a style="color: #4587f5" @click="showSpeakerInfo(index)">
              {{ text }}
            </a>
            <a-icon
              v-if="!isFlow"
              type="search"
              class="cursor-pointer hover:text-blue-500 float-right mt-1"
              @click="showSpeakerVisible('update', index)"
            ></a-icon>
          </template>
          <!-- 讲者咨询费 -->
          <template slot="fee" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.appConsultingFee }}
            </span>
            <a-input
              v-else
              v-model="record.appConsultingFee"
              :oninput="oninput['digit']"
              @change="calcFee"
            ></a-input>
          </template>
          <!-- 讲者课题 -->
          <template slot="topic" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.topicname }}
            </span>
            <a-input v-else v-model="record.topicname"></a-input>
          </template>
          <!-- 讲课时长 -->
          <template slot="lecture" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.lengthoflecture }}
            </span>
            <a-input
              v-else
              v-model="record.lengthoflecture"
              :oninput="oninput['number']"
            ></a-input>
          </template>
        </a-table>
        <a-button-group v-if="!isFlow">
          <a-button
            icon="plus"
            title="从最后一位讲者后增加一位讲者"
            @click="showSpeakerVisible('add')"
          >
            添加
          </a-button>
          <a-button
            icon="minus"
            type="danger"
            title="删除最后一位讲者"
            @click="delLastSpeaker"
          >
            删除
          </a-button>
        </a-button-group>
        <div class="mt-2 text-right">
          <span class="font-bold">讲者劳务费预算合计 :</span>
          <span class="ml-2 text-red-600">
            {{ speaker.budgetConsultTotal }}
          </span>
        </div>
      </div>

      <div class="border-b py-4 text-sm font-semibold">
        <a-icon type="pay-circle" />
        <span class="ml-2">费用信息</span>
      </div>
      <div class="p-4">
        <a-table
          bordered
          rowKey="title"
          :columns="feeColumns"
          :dataSource="feeDatas"
          :pagination="false"
          :rowClassName="
            record => {
              return record.deprecated ? 'deprecated-row' : ''
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="priceTitle">
            <span class="app-form-required">单价</span>
          </template>
          <template slot="numTitle">
            <span class="app-form-required">数量</span>
          </template>
          <template slot="totalTitle">
            <span class="app-form-required">总价</span>
          </template>

          <!-- 附件上传 -->
          <template slot="upload" slot-scope="text, record">
            <span v-if="!record.upload">~</span>
            <a v-else style="color: #4587f5" @click="openFile(record)">
              <span>{{ isFlow ? '附件预览' : '附件上传' }}</span>
              <span>
                (
                {{ JSON.parse(fee[record.upload] || '[]').length }}
                )
              </span>
            </a>
          </template>
          <!-- 总价 -->
          <template slot="total" slot-scope="text, record">
            <span v-if="typeof record.total === 'function'">
              {{ record.total() }}
            </span>
            <span v-else-if="isFlow || record.deprecated">
              {{ fee[record.total] || 0 }}
            </span>
            <a-input
              v-else
              v-model="fee[record.total]"
              :oninput="oninput['digit']"
            ></a-input>
          </template>
          <!-- 数量 -->
          <template slot="num" slot-scope="text, record">
            <span v-if="record.num === '~'">
              {{ record.num }}
            </span>
            <span v-else-if="isFlow || record.deprecated">
              {{ fee[record.num] || 0 }}
            </span>
            <a-input
              v-else
              v-model="fee[record.num]"
              :oninput="oninput['number']"
            ></a-input>
          </template>
          <!-- 单价 -->
          <template slot="price" slot-scope="text, record">
            <span v-if="record.price === '~'">
              {{ record.price }}
            </span>
            <span v-else-if="isFlow || record.deprecated || record.clacTotal">
              {{ fee[record.price] || 0 }}
            </span>
            <a-input
              v-else
              v-model="fee[record.price]"
              :oninput="oninput['digit']"
            ></a-input>
          </template>
        </a-table>
        <a-row type="flex" justify="end" class="mt-2">
          <a-col :span="4">
            <span class="font-bold">预付金额 :</span>
            <span class="text-red-600 ml-2">{{ budgetPreCost }}</span>
          </a-col>
          <a-col :span="4">
            <span class="font-bold">预算合计金额 :</span>
            <span class="text-red-600 ml-2">{{ fee.budgetTotal }}</span>
          </a-col>
          <a-col :span="4">
            <span class="font-bold">批准总预算 :</span>
            <span class="text-red-600 ml-2">{{ fee.budgetApprovetotal }}</span>
          </a-col>
        </a-row>
      </div>
      <!-- 发起人附言 -->
      <post-script
        v-if="form.flowInfoDTO"
        billType="XSSQ"
        :pid="this.pid"
        :uid="form.applicant"
        :status="form.flowInfoDTO.status"
      ></post-script>
      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDTO"
        :status="isFlow"
        :summaryid="form.flowInfoDTO.summaryid"
        :nextapproveman="form.flowInfoDTO.nextapproveman"
        :curApprover="form.flowInfoDTO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicant" :billId="form.id"></comment>
    </a-form>

    <a-drawer
      title="讲者信息"
      placement="right"
      width="720"
      :closable="false"
      :visible="speakerVisible"
      @close="speakerVisible = false"
    >
      <speaker-drawer
        ref="speakerDrawer"
        :speakers="speaker.speakers"
        :currentIdx="drawerInx"
      ></speaker-drawer>
    </a-drawer>

    <a-drawer
      title="活动类型及执行标准详情"
      placement="right"
      width="720"
      :closable="false"
      :visible="standardVisible"
      @close="standardVisible = false"
    >
      <standard-drawer :avtivityType="standardInfo"></standard-drawer>
    </a-drawer>

    <!-- 选择活动类型 -->
    <choose-active-modal
      ref="activemodel"
      :conferenceType="getConferenceType"
      @activeType="chooseActive"
    ></choose-active-modal>

    <!-- 选择陪同人员 -->
    <choose-accompany-modal
      ref="accompanymodel"
      :accPersonList="accompany.appAccompanyingPersonsDtos"
      @chooseAccPerson="chooseAccPerson"
    ></choose-accompany-modal>

    <!-- 选择讲者 -->
    <choose-speaker-modal
      ref="speakermodel"
      :speakers="speaker.speakers"
      @chooseSpeaker="chooseSpeaker"
    ></choose-speaker-modal>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <!-- 学术申请打印 -->
    <print-wrapper ref="printWrapper" v-show="printVisible">
      <xssq-print
        :datas="form"
        :options="options"
        :conferenceList="conferenceList"
        :productList="productList"
        :businessTypeList="businessTypeList"
        :activityFormList="activityFormList"
      ></xssq-print>
    </print-wrapper>

    <!-- 打印选择附言、审批意见、流程信息 -->
    <modal
      v-if="showModal"
      title="选择打印的信息"
      @handldClick="handldPrintlick"
      @close="showModal = false"
    >
      <a-checkbox-group
        v-model="options"
        name="checkboxgroup"
        :options="plainOptions"
      />
    </modal>

    <map-modal
      v-if="showMap"
      @close="showMap = false"
      @confirm="getActivityPlace"
    ></map-modal>
  </div>
</template>

<script>
// mixin
import userMixin from './mixins/user-mix'
import activityMixin from './mixins/activity-mix'
import standardMixin from './mixins/standard-mix'
import accompanyMixin from './mixins/accompany-mix'
import speakerMixin from './mixins/speaker-mix'
import feeMixin from './mixins/fee-mix'
import verityMix from './mixins/verity-mix'
// components
import SpeakerDrawer from './components/SpeakerDrawer'
import StandardDrawer from './components/StandardDrawer'
import ChooseActiveModal from './components/ChooseActiveModal'
import ChooseSpeakerModal from './components/ChooseSpeakerModal'
import ChooseAccompanyModal from './components/ChooseAccompanyModal'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import PostScript from '@/components/flow/PostScript'
import LoadingStuff from '@/components/spin/LoadingStuff'
import Modal from '@/components/Modal'
import PrintWrapper from 'vue-easy-print'
import XssqPrint from './components/XssqPrint'
import MapModal from './components/MapModal'
// api
import { isDisabled } from '@/utils/util'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import {
  getActivityAppById,
  getEditActivityApp,
  getActivityApp,
  getSubmitXSSQ,
  getDeleteActivityApp,
  getDeptByUid,
  getOACancel2
} from '@/api/acdemicActive/acdemic-application'
import { oninput } from '@/utils/validate'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'AppFormDetail',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    SpeakerDrawer,
    StandardDrawer,
    ChooseActiveModal,
    ChooseSpeakerModal,
    ChooseAccompanyModal,
    UploadModal,
    PreviewModal,
    Process,
    Comment,
    PostScript,
    LoadingStuff,
    Modal,
    PrintWrapper,
    XssqPrint,
    MapModal
  },
  mixins: [
    userMixin,
    activityMixin,
    standardMixin,
    accompanyMixin,
    speakerMixin,
    feeMixin,
    verityMix
  ],
  data() {
    return {
      labelCol: { span: 20 },
      wrapperCol: { span: 20 },
      labelTextAreaCol: { span: 20 },
      wrapperTextAreaCol: { span: 24 },

      oninput,

      mTimeStr: '', // 会议场次字段
      loading: false,
      isInit: false,
      form: { flowInfoDTO: { status: '1' } },

      // 打印
      printVisible: false,
      showModal: false,
      options: ['flow', 'ps', 'comment'],
      plainOptions: [
        { label: '流程信息', value: 'flow' },
        { label: '发起人附言', value: 'ps' },
        { label: '审批意见', value: 'comment' }
      ],

      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: ''
    }
  },
  computed: {
    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      return isDisabled(status)
    },

    showAvatar() {
      return this.$store.getters.protal
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    }
  },

  created() {
    if (this.type === 'check' || this.type === 'waitwork') {
      this.loading = true
      this.isInit = true // 表单数据是否在初始化。
      getActivityAppById(this.pid)
        .then(res => {
          this.form = res
          // 初始化数据
          this.initData()
          // 数据分发
          this.distributeData(this.user)
          this.distributeData(this.activity)
          this.distributeData(this.standard)
          this.distributeData(this.accompany)
          this.distributeData(this.speaker)
          this.distributeData(this.fee)
        })
        .finally(() => {
          this.isInit = false
          this.loading = false
        })
    }
  },

  methods: {
    initData() {
      // 申请人
      this.$set(this.form, 'appName', this.form.applicantDTO.username)
      // 大区
      if (this.form.regionDTO)
        this.$set(this.form, 'regionName', this.form.regionDTO.deptname)
      // 省区
      if (this.form.provinceDTO)
        this.$set(this.form, 'provinceName', this.form.provinceDTO.deptname)
      // 地办
      if (this.form.localityDTO)
        this.$set(this.form, 'localityName', this.form.localityDTO.deptname)
      // 费用承担部门
      if (this.form.departmentDTO)
        this.$set(this.form, 'departmentName', this.form.departmentDTO.deptname)
      // 会议场数
      const { meetingsTimeStatistics: m } = this.form
      if (m)
        this.mTimeStr = `${m.yearMonth}_${m.activityTypeName}_第${m.times}场, 全年累计${m.yearTimes}场`
      // 单据状态(向上通知)
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      // 处理待发无流程状态的问题
      if (!this.form.flowInfoDTO) {
        this.form.flowInfoDTO = {}
        this.$set(this.form.flowInfoDTO, 'status', '1') // 单据状态初始化
      }

      // 年场次提示信息
      this.overMeetUpperLimit(
        { yearMeetingTimeInfo: this.form.meetingsTimeStatistics } || {}
      )

      // 总部项目名称，处理历史数据
      if (
        this.form.headQuartersProjectName &&
        !this.form.headQuartersProjectId
      ) {
        this.form.headQuartersProjectId = this.form.headQuartersProjectName
      }

      // 申请人部门列表
      getDeptByUid(this.form.applicant).then(depts => {
        this.userForm[7].options = this.changeDeptProps(depts)
      })

      this.$emit('status', status)
    },

    distributeData(module) {
      Object.keys(module).forEach(key => this.$set(module, key, this.form[key]))
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.title || item.label
      const fileProp = item.upload || item.prop
      const module = item.upload ? this.fee : this.activity

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = module[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = module[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '预邀客户名单':
          this.activity.participantsList = fileListInfo
          break
        case '邀请函/会议议程':
          this.activity.activityInvitation = fileListInfo
          break
        case '主办方资质证明':
          this.activity.sponsorQualification = fileListInfo
          break
        case '主办方邀请函/招商函':
          this.activity.sponsorInvitation = fileListInfo
          break
        case '协办合同':
          this.activity.contract = fileListInfo
          break
        case '线上直播平台服务协议/报价单':
          this.activity.onlineStreamingPlatformAgreement = fileListInfo
          break
        case '会务合同':
          this.activity.contractScan = fileListInfo
          break
        case '会务物料制作费':
          this.fee.meetingMaterialExpenseDocs = fileListInfo
          break
        default:
          break
      }
    },

    // 选择打印信息
    handldPrintlick() {
      this.print()
      this.showModal = false
    },

    // 学术申请单打印
    print() {
      this.assgin()
      this.printVisible = true
      this.$nextTick(() => this.$refs['printWrapper'].print())
    },

    assgin() {
      const user = cloneDeep(this.user)
      const activity = cloneDeep(this.activity)
      const standard = cloneDeep(this.standard)
      const accompany = cloneDeep(this.accompany)
      const speaker = cloneDeep(this.speaker)
      const fee = cloneDeep(this.fee)
      this.form = {
        ...this.form,
        ...user,
        ...activity,
        ...standard,
        ...accompany,
        ...speaker,
        ...fee
      }
    },

    filterData(apply) {
      const fileIds = []

      // 非线上
      if (!this.isShowOnline) {
        apply.onlineLiveBroadcastingFee = ''

        // 线上直播平台服务协议附件
        if (apply.onlineStreamingPlatformAgreement) {
          const files = JSON.parse(apply.onlineStreamingPlatformAgreement)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.onlineStreamingPlatformAgreement = ''
        }
      }

      // 非协办
      if (!this.isShowCo) {
        apply.sponsor = ''
        apply.sponsorEstdate = ''
        apply.cooperateContractMny = ''

        // 主办方资质证明附件
        if (apply.sponsorQualification) {
          const files = JSON.parse(apply.sponsorQualification)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.sponsorQualification = ''
        }

        // 主办方邀请函/招商函附件
        if (apply.sponsorInvitation) {
          const files = JSON.parse(apply.sponsorInvitation)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.sponsorInvitation = ''
        }

        // 协办合同附件
        if (apply.contract) {
          const files = JSON.parse(apply.contract)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.contract = ''
        }
      }

      // 非会务公司
      if (!this.isShowContract) {
        apply.confCompany = ''
        apply.contractMoney = ''

        // 会务合同附件
        if (apply.contractScan) {
          const files = JSON.parse(apply.contractScan)
          files.forEach(item => {
            fileIds.push(item.id)
          })
          apply.contractScan = ''
        }
      }

      if (fileIds.length) {
        getDeleteFiles(fileIds)
      }

      return apply
    },

    overMeetUpperLimit({ yearMeetingTimeInfo = {} }) {
      const {
        activityTypeName,
        yearTimes = 0,
        yearTimesUpperLimit = 0
      } = yearMeetingTimeInfo

      if (yearTimes > yearTimesUpperLimit)
        this.$message.warning({
          content: `活动类型: ${activityTypeName} 、申请人: ${
            this.$store.getters.username
          } 会议申请已超过年场次上限${yearTimes - yearTimesUpperLimit}`,
          duration: 4
        })
    },

    //保存
    save(callback) {
      this.assgin()
      this.saveForm(callback)
    },

    saveForm(callback) {
      const saveApi = this.form.id ? getEditActivityApp : getActivityApp
      this.form = this.filterData(this.form)
      const submitData = cloneDeep(this.form)

      // 如果选择的是总部项目的id，不在枚举列表里，则把总部项目id制空（兼容历史单据）
      if (submitData.headQuartersProjectId) {
        const list = this.activityForm[23].options.map(item => item.value)
        if (!list.includes(submitData.headQuartersProjectId)) {
          submitData.headQuartersProjectId = ''
        }
      }

      saveApi(submitData)
        .then(res => {
          this.delUploadFile()
          this.$message.success('保存成功')
          //门户保存crm单据时，直接关闭窗口
          if (this.showAvatar) window.close()
          if (!this.isUpdate) {
            this.overMeetUpperLimit(res)
            //未做更改，点击保存按钮
            this.$router.replace({ name: 'activityApplication' })
          } else {
            // 做更改保存，非点击按钮触发事件
            if (typeof callback === 'function') {
              callback(true)
            } else {
              //点击按钮触发事件
              this.isUpdate = false
              this.overMeetUpperLimit(res)
              this.$router.replace({ name: 'activityApplication' })
            }
          }
        })
        .catch(() => {
          if (typeof callback === 'function') callback(false)
        })
    },

    //提交
    submit() {
      this.assgin()
      // this.Update = false
      this.form = this.filterData(this.form)
      const submitData = cloneDeep(this.form)

      // 如果选择的是总部项目的id，不在枚举列表里，则把总部项目id制空（兼容历史单据）
      if (submitData.headQuartersProjectId) {
        const list = this.activityForm[23].options.map(item => item.value)
        if (!list.includes(submitData.headQuartersProjectId)) {
          submitData.headQuartersProjectId = ''
        }
      }

      if (this.verityAppForm()) {
        getSubmitXSSQ(submitData).then(res => {
          this.delUploadFile()
          this.overMeetUpperLimit(res)
          this.$message.success('提交成功')

          if (this.showAvatar) window.close()
          this.$router.replace({ name: 'activityApplication' })
        })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该学术推广活动申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getDeleteActivityApp(this.form.id).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) window.close()
            this.$router.replace({ name: 'activityApplication' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该学术活动申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'XSSQ',
            this.form.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDTO.status = '5'
            this.fee.budgetApprovetotal = 0 //批准总预算为0
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    }
  }
}
</script>

<style lang="less" scoped>
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
/deep/ .ant-table-thead {
  & > tr > th {
    font-size: 12px;
  }
}
/deep/ .ant-table-tbody > tr > td {
  font-size: 12px;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/.ant-form-item-label {
  line-height: 24px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
/deep/ .deprecated-row {
  background: #d9d9d9;
}
/deep/.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}

.app-form-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
</style>

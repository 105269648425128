import ProductManage from '@/views/product'
export default {
  path: 'product',
  name: 'product',
  component: ProductManage,
  redirect: 'product/productManage',
  children: [
    // 产品线管理路由
    {
      // 产品线管理
      path: 'productManage',
      name: 'productManage',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/productmanage'
        ),
      meta: { title: '产品线管理', menuKey: 'productManage' }
    },
    {
      // 产品详情
      path: 'productDetial',
      name: 'productDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/productmanage/ProductDetial'
        ),
      meta: { title: '产品线详情', menuKey: 'productManage' }
    },
    {
      // 新增产品
      path: 'addProduct',
      name: 'addProduct',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/productmanage/AddProduct'
        ),
      meta: { title: '新增产品线', menuKey: 'productManage' }
    },

    // 产品关键信息路由
    {
      // 产品关键信息
      path: 'productKeyInfoManage',
      name: 'productKeyInfoManage',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/productinfo'
        ),
      meta: { title: '产品关键信息管理', menuKey: 'productKeyInfoManage' }
    },
    {
      // 产品关键信息详情
      path: 'productInfoDetial',
      name: 'productInfoDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/productinfo/ProductInfoDetial'
        ),
      meta: { title: '产品关键信息详情', menuKey: 'productKeyInfoManage' }
    },
    {
      // 新增产品关键信息
      path: 'addProductInfo',
      name: 'addProductInfo',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/productinfo/AddProductInfo'
        ),
      meta: { title: '新增产品关键信息', menuKey: 'productKeyInfoManage' }
    },

    // 剂型管理路由
    {
      // 剂型管理
      path: 'drugForm',
      name: 'drugForm',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/drugform'
        ),
      meta: { title: '产品管理', menuKey: 'drugForm' }
    },
    {
      // 产品关键信息详情
      path: 'drugformDetial',
      name: 'drugformDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/drugform/DrugformDetial'
        ),
      meta: { title: '产品详情', menuKey: 'drugForm' }
    },
    {
      // 新增产品关键信息
      path: 'addDrugform',
      name: 'addDrugform',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/product/drugform/AddDrugform'
        ),
      meta: { title: '新增产品', menuKey: 'drugForm' }
    }
  ]
}

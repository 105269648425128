import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/************查询学术推广活动申请单 ****************/
/**
 *  查询所有活动申请信息
 * @param {Object} param0 分页参数
 * @param {Array} queryParams 查询条件
 */
export function getActivityAppList(
  { current: pageindex, pageSize: pagenum },
  queryParams
) {
  return request({
    method: 'post',
    url: `queryApproveBills/activityApp/${pageindex}/${pagenum}`,
    data: queryParams
  })
}

// 根据ID来查询活动申请
export function getActivityAppItem(id) {
  return request({
    method: 'get',
    url: `queryApproveBills/activityApp/${id}`
  })
}

/************查询学术推广活动总结单 ****************/
/**
 *  查询所有活动总结信息
 * @param {Object} param0 分页参数
 * @param {Array} queryParams 查询条件
 */
export function getActivityReimList(
  { current: pageindex, pageSize: pagenum },
  queryParams
) {
  return request({
    method: 'post',
    url: `queryApproveBills/activityReimbursement/${pageindex}/${pagenum}`,
    data: queryParams
  })
}

// 根据ID来查询活动总结
export function getActivityReimItem(id) {
  return request({
    method: 'get',
    url: `queryApproveBills/activityReimbursement/${id}`
  })
}

/*********** 报销单据 *****************/
/**
 *  查询所有对公付款单
 * @param {Object} param0 分页参数
 * @param {Array} queryParams 查询条件
 */
export function getDGPaymentList(
  { current: pageIndex, pageSize: pageNum },
  queryParams
) {
  return request({
    method: 'post',
    url: `queryApproveBills/payment/listDGPayment`,
    params: { pageIndex, pageNum },
    data: queryParams
  })
}

/**
 *  查询所有对私付款单
 * @param {Object} param0 分页参数
 * @param {Array} queryParams 查询条件
 */
export function getDSPaymentList(
  { current: pageIndex, pageSize: pageNum },
  queryParams
) {
  return request({
    method: 'post',
    url: `queryApproveBills/payment/listDSPayment`,
    params: { pageIndex, pageNum },
    data: queryParams
  })
}

/**
 *  查询所有预付款单
 * @param {Object} param0 分页参数
 * @param {Array} queryParams 查询条件
 */
export function getYFPaymentList(
  { current: pageIndex, pageSize: pageNum },
  queryParams
) {
  return request({
    method: 'post',
    url: `queryApproveBills/payment/listYFPayment`,
    params: { pageIndex, pageNum },
    data: queryParams
  })
}

/**
 *  查询所有房产付款单
 * @param {Object} param0 分页参数
 * @param {Array} queryParams 查询条件
 */
export function getHousePaymentList(pageIndex, pageNum, billType, queryParams) {
  return request({
    method: 'post',
    url: `queryApproveBills/payment/listHousePayment`,
    params: { pageIndex, pageNum, billType },
    data: queryParams
  })
}

// 根据ID来查询报销单据
export function getPaymentItem(id) {
  return request({
    method: 'get',
    url: `queryApproveBills/payment/getPayment`,
    params: { id }
  })
}

//部门详情
export function getDeptInfo(params) {
  return request({
    url: `queryApproveBills/getdeptbyId`,
    method: 'get',
    params: {
      ...params
    }
  })
}

/**
 * 获取用户列表
 * @param {String} pageindex
 * @param {String} pagenum
 */
export function getUserList(page, params, data = []) {
  return request({
    method: 'post',
    url: `queryApproveBills/pageAll/${page.current}/${page.pageSize}`,
    params: {
      ...params
    },
    data: data
  })
}

/**
 *导出对公付款单
 */
export function exportDGPaymentExcel(data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportDGPaymentExcel`,
    responseType: 'arraybuffer',
    data: data
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `查询对公付款单`)
  })
}

/**
 *导出对私付款单
 */
export function exportDSPaymentExcel(data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportDSPaymentExcel`,
    responseType: 'arraybuffer',
    data: data
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `查询对私付款单`)
  })
}

/**
 *导出申请总结单信息请求（返回文件id）
 * @param {Array} data 查询条件
 * @param {Object} params
 */
export function exportAppReimExcelAction(data, params = {}) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportAppReimExcelAction`,
    params: {
      ...params
    },
    data: data
  })
}

/**
 *导出预付款单信息请求（返回文件id）
 * @param {Array} data 查询条件
 */
export function exportYFPaymentExcelAction(data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportYFPaymentExcelAction`,
    data: data
  })
}

/**
 *导出对私付款单信息请求（返回文件id）
 * @param {Array} data 查询条件
 */
export function exportDSPaymentExcelAction(data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportDSPaymentExcelAction`,
    data: data
  })
}

/**
 *导出对公付款单信息请求（返回文件id）
 * @param {Array} data 查询条件
 */
export function exportDGPaymentExcelAction(data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportDGPaymentExcelAction`,
    data: data
  })
}

/**
 * 导出对私付款单请求（返回文件id）
 * @param {Array} data 查询条件
 */
export function exportDsPayNs(data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportDsPayNs`,
    data: data
  })
}

/**
 * 导出对私付款单请求（返回文件id）
 * @param {Array} data 查询条件
 */
export function exportHousePaymentExcelAction(billType, data) {
  return request({
    method: 'post',
    url: `queryApproveBills/exportHousePaymentExcelAction`,
    params: { billType },
    data: data
  })
}

/**
 *导出付款单信息请求（返回文件id）
 * @param {Array} fileId 文件id
 * @param {String} billName 导出的单据名
 */
export function getExportExcelFileByFileKey(fileId, billName, isExcel = false) {
  return request({
    method: 'get',
    url: `queryApproveBills/getExportExcelFileByFileKey`,
    params: { fileKey: fileId },
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res, true)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    if (!data.resultCode) {
      const docType = isExcel ? 'excel' : 'csv'
      downLoadFile(data, docType, `${billName}`)
      return
    }

    return data.resultCode
  })
}

/**
 * 查询月销量指标列表（按表体来分页过滤）
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getMonthSaleTargetList(
  { current: pageindex, pageSize: pagenum },
  conditions = []
) {
  return request({
    method: 'post',
    url: `query/monthlySalesTarget/bodyForm/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 查询月质变详情
 * @param {String} id
 */
export function getMonthSaleTargetByBid(id) {
  return request({
    method: 'post',
    url: `query/monthlySalesTarget/bodyForm/getOneById/${id}`
  })
}

/**
 * 月销量指标导出（返回文件key）
 * @param {Array} data 查询条件
 */
export function getExportMonthlyTargetAction(data) {
  return request({
    method: 'post',
    url: `query/monthlySalesTarget/bodyForm/exportMonthlyTargetAction`,
    data: data
  })
}

/**
 * 查询接待申请列表（按表体来分页过滤）
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getReceptList(
  { current: pageindex, pageSize: pagenum },
  conditions = []
) {
  return request({
    method: 'post',
    url: `recApp/query/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 查看接待申请明细
 * @param {String} id
 * @returns
 */
export function getReceptById(id) {
  return request({
    method: 'get',
    url: `recApp/query/getOne`,
    params: { id }
  })
}

/**
 *导出csv信息请求（返回文件id）
 * @param {Array} fileId 文件id
 * @param {String} billName 导出的单据名
 */
export function getExportCsv(fileId, billName, url) {
  return request({
    method: 'get',
    url: url,
    params: { fileKey: fileId },
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res, true)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    if (!data.resultCode) {
      downLoadFile(data, 'csv', `${billName}`)
      return
    }

    return data.resultCode
  })
}

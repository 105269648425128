import CustManage from '@/views/custmanage'
export default {
  path: 'custManage',
  name: 'customer',
  component: CustManage,
  redirect: 'custManage/hospitalManage',
  children: [
    {
      // 医院管理
      path: 'hospitalManage',
      name: 'hospitalManage',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospmanage/HospList'
        ),
      meta: { title: '医院管理', menuKey: 'hospitalManage' }
    },
    {
      // 医院详情
      path: 'hosptabs/:hospid',
      name: 'hosptabs',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospmanage/HospTabs'
        ),
      meta: { title: '医院详情', menuKey: 'hospitalManage' }
    },
    {
      // 新增医院
      path: 'hospital-add',
      name: 'hospitalAdd',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospmanage/HospAdd'
        ),
      meta: { title: '新增医院', menuKey: 'hospitalManage' }
    },
    {
      // 医生管理
      path: 'doctorManage',
      name: 'doctorManage',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctormanage/DoctorList'
        ),
      meta: { title: '医生管理', menuKey: 'doctorManage' }
    },
    {
      // 医生详情
      path: 'doctorform/:doctorid',
      name: 'doctorform',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctormanage/DoctorForm'
        ),
      meta: { title: '医生详情', menuKey: 'doctorManage' }
    },
    {
      // 新增医生
      path: 'doctor-add',
      name: 'doctorAdd',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctormanage/DoctorAdd'
        ),
      meta: { title: '新增医生', menuKey: 'doctorManage' }
    },
    {
      // 讲者管理
      path: 'speakerManage',
      name: 'speakerManage',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakermanage/SpeakerList'
        ),
      meta: { title: '讲者管理', menuKey: 'speakerManage' }
    },
    {
      // 新增讲者
      path: 'speaker-add',
      name: 'speakerAdd',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakermanage/SpeakerAdd'
        ),
      meta: { title: '新增讲者', menuKey: 'speakerManage' }
    },
    {
      // 讲者详情
      path: 'speakertabs/:speakerid',
      name: 'speakertabs',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakermanage/SpeakerTabs'
        ),
      meta: { title: '讲者详情', menuKey: 'speakerManage' }
    },
    {
      // 讲者历史列表
      path: 'speakerhislist',
      name: 'speakerhislist',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakermanage/SpeakerHisList'
        ),
      meta: { title: '讲者历史列表', menuKey: 'speakerManage' }
    },
    {
      // 讲者历史
      path: 'speakerhisform/:speakerhisid',
      name: 'speakerhisform',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakermanage/SpeakerHisForm'
        ),
      meta: { title: '讲者历史详情', menuKey: 'speakerManage' }
    },

    {
      // 数据合并
      path: 'dataMerge',
      name: 'dataMerge',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/index'
        ),
      meta: { title: '数据合并', menuKey: 'dataMerge' }
    },
    {
      // 医院数据合并详情
      path: 'mergeHospDetial',
      name: 'mergeHospDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/MergeHospDetial'
        ),
      meta: { title: '医院数据合并详情', menuKey: 'dataMerge' }
    },
    {
      // 新增医院数据合并
      path: 'addMergeHosp',
      name: 'addMergeHosp',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/AddMergeHosp'
        ),
      meta: { title: '新增医院数据合并', menuKey: 'dataMerge' }
    },
    {
      // 医生数据合并详情
      path: 'mergeDoctDetial',
      name: 'mergeDoctDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/MergeDoctDetial'
        ),
      meta: { title: '医生数据合并详情', menuKey: 'dataMerge' }
    },
    {
      // 新增医生数据合并
      path: 'addMergeDoct',
      name: 'addMergeDoct',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/AddMergeDoct'
        ),
      meta: { title: '新增医生数据合并', menuKey: 'dataMerge' }
    },
    {
      // 讲者数据合并详情
      path: 'mergeSpeakerDetial',
      name: 'mergeSpeakerDetial',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/MergeSpeakerDetial'
        ),
      meta: { title: '讲者数据合并详情', menuKey: 'dataMerge' }
    },
    {
      // 新增讲者数据合并
      path: 'addMergeSpeaker',
      name: 'addMergeSpeaker',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/merge/AddMergeSpeaker'
        ),
      meta: { title: '讲者数据合并', menuKey: 'dataMerge' }
    },
    {
      // 变更申请列表
      path: 'appModify',
      name: 'appModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify'
        ),
      meta: { title: '变更申请管理', menuKey: 'appModify' }
    },
    {
      // 新增讲者数据合并
      path: 'doctorModify',
      name: 'doctorModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify/DoctorModify'
        ),
      meta: { title: '医生变更申请详情', menuKey: 'appModify' }
    },
    {
      // 新增讲者数据合并
      path: 'hospModify',
      name: 'hospModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify/HospModiify'
        ),
      meta: { title: '医院变更申请详情', menuKey: 'appModify' }
    },
    {
      // 新增讲者数据合并
      path: 'speakerModify',
      name: 'speakerModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify/SpeakerModify'
        ),
      meta: { title: '讲者变更申请详情', menuKey: 'appModify' }
    },
    {
      // 拜访补签申请
      path: 'visitModify',
      name: 'visitModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify/VisitModify'
        ),
      meta: { title: '拜访补签申请详情', menuKey: 'appModify' }
    },
    {
      // 次月目标医生
      path: 'targetDoctorModify',
      name: 'targetDoctorModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify/TargetDoctorModify'
        ),
      meta: { title: '次月目标医生申请详情', menuKey: 'appModify' }
    },
    {
      // 目标医院
      path: 'targetHospModify',
      name: 'targetHospModify',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/modify/TargetHospModify'
        ),
      meta: { title: '目标医院申请详情', menuKey: 'appModify' }
    },

    /***** 医院变更申请 ********/
    {
      // 医院变更申请
      path: 'hospitalPcApp',
      name: 'hospitalPcApp',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospitalApp'
        ),
      meta: { title: '医院变更申请', menuKey: 'hospitalPcApp' }
    },
    {
      // 添加区域医生
      path: 'addAreaHospital',
      name: 'addAreaHospital',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospitalApp/AddAreaHospital'
        ),
      meta: { title: '添加区域医院', menuKey: 'hospitalPcApp' }
    },
    {
      // 修改区域医生
      path: 'editAreaHospital',
      name: 'editAreaHospital',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospitalApp/EditAreaHospital'
        ),
      meta: { title: '修改区域医院', menuKey: 'hospitalPcApp' }
    },
    {
      // 移除区域医生
      path: 'removeAreaHospital',
      name: 'removeAreaHospital',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/hospitalApp/RemoveAreaHospital'
        ),
      meta: { title: '移除区域医院', menuKey: 'hospitalPcApp' }
    },
    /***** 医生变更申请 ********/
    {
      // 医生变更申请
      path: 'doctorPcApp',
      name: 'doctorPcApp',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctorApp'
        ),
      meta: { title: '医生变更申请', menuKey: 'doctorPcApp' }
    },
    {
      // 添加区域医生
      path: 'addAreaDoctor',
      name: 'addAreaDoctor',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctorApp/AddAreaDoctor'
        ),
      meta: { title: '添加区域医生', menuKey: 'doctorPcApp' }
    },
    {
      // 新增医生
      path: 'addDoctor',
      name: 'addDoctor',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctorApp/AddDoctor'
        ),
      meta: { title: '新增医生', menuKey: 'doctorPcApp' }
    },
    {
      // 修改区域医生
      path: 'editAreaDoctor',
      name: 'editAreaDoctor',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctorApp/EditAreaDoctor'
        ),
      meta: { title: '修改区域医生', menuKey: 'doctorPcApp' }
    },
    {
      // 移除区域医生
      path: 'removeAreaDoctor',
      name: 'removeAreaDoctor',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/doctorApp/RemoveAreaDoctor'
        ),
      meta: { title: '移除区域医生', menuKey: 'doctorPcApp' }
    },
    /***** 讲者变更申请 ********/
    {
      // 讲者变更申请
      path: 'speakerPcApp',
      name: 'speakerPcApp',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakerApp'
        ),
      meta: { title: '讲者变更申请', menuKey: 'speakerPcApp' }
    },
    {
      // 医生转讲者申请
      path: 'addSpeaker',
      name: 'addSpeaker',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakerApp/AddSpeaker'
        ),
      meta: { title: '医生转讲者', menuKey: 'speakerPcApp' }
    },
    {
      // 修改讲者信息申请
      path: 'editSpeaker',
      name: 'editSpeaker',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/speakerApp/EditSpeaker'
        ),
      meta: { title: '修改讲者信息', menuKey: 'speakerPcApp' }
    },
    {
      // 学术业务区域
      path: 'chnlArea',
      name: 'chnlArea',
      component: () =>
        import(
          /* webpackChunkName: "group-custmanage" */ '@/views/custmanage/chnlArea'
        ),
      meta: { title: '学术业务区域', menuKey: 'chnlArea' }
    }
  ]
}

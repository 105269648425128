<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">药连锁公司驻地宿舍申请单详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        :loading="dataLoading"
        class="ml-2"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'houseDormitory' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
        @select-change="onUserChange"
      ></base-form-items>

      <base-form-title title="申请住房基本信息" icon="home" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
        :formTextAreaList="formBaseTextAreaList"
        @select-change="onSelectChange"
        @open-modal="openModal"
      ></base-form-items>

      <template v-if="isFlow">
        <base-form-title title="住/退/换房信息" icon="home" />
        <base-form-items
          :formData="form"
          :formBaseList="formReimList"
        ></base-form-items>
      </template>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDO"
        :status="isFlow"
        :summaryid="form.flowInfoDO.summaryid"
        :nextapproveman="form.flowInfoDO.nextapproveman"
        :curApprover="form.flowInfoDO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicant" :billId="form.id"></comment>
    </base-form>

    <!-- 员工 -->
    <UserModal
      :visible="showUserModal"
      :title="`申请人查询`"
      @choose="onChooseUser"
      @close="showUserModal = false"
    ></UserModal>

    <HouseArchivesModal
      :visible="showModal"
      @choose="chooseHouseArchives"
      @close="showModal = false"
    ></HouseArchivesModal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import UserModal from '@/components/Modal/UserModal'
import HouseArchivesModal from '@/components/Modal/HouseArchivesModal'
// api
import moment from 'moment'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { getDoc } from '@/configs/doc-config'
import {
  saveHouseDormitory,
  updateHouseDormitory,
  delHouseDormitory,
  getHouseDormitory,
  submitHouseDormitory,
  getNewInHouseInfoByUid
} from '@/api/house/dormitory-house'
import {
  getOACancel2,
  getDeptByUid
} from '@/api/acdemicActive/acdemic-application'

// mixin
import userMixin from '../mixins/user-mixin'
import verityMixin from '../mixins/verity-mixin'

const genderOptions = [
  { text: '男', value: '男' },
  { text: '女', value: '女' }
]

export default {
  name: 'HouseDormitoryForm',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    HouseArchivesModal,
    UserModal,
    Process,
    Comment
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,
      showModal: false,
      showUserModal: false,
      dataLoading: false, // 提交数据

      /* eslint-disable prettier/prettier */
      form: {
        appdate: '', // 申请日期
        applicant: '', // 申请人id
        applicantName: '', // 申请人名称
        post: '', //岗位
        contact: '', // 联系方式
        region: '', // 大区id
        regionName: '', // 大区名称
        province: '', // 省办id
        provinceName: '', // 省办名称
        locality: '', // 地办id
        localityName: '', // 地办名称
        applicantDepartment: '', //部门id

        creator: '', // 制单人

        dormitoryType: '', // 申请类型
        dormitoryTypeCode: '', // 申请类型Code
        gender: '', // 性别
        idno: '', // 身份证号
        hireDate: '', // 入职日期

        applyHouseArchiveId: '', // 申请住房Id
        applyHouseAddress: '', // 申请住房
        applyHouseArchiveCode: '', // 房产编号

        applyHouseTypeId: '', // 申请户型Id
        applyHouseTypeCode: '', // 申请户型编号
        applyHouseTypeName: '', // 申请户型

        lastHouseArchiveId: '', // 原住房Id
        lastApplyHouseAddress: '', // 原住房
        lastHouseArchiveCode: '', // 原住房编号

        lastApplyHouseTypeId: '', // 原住房户型Id
        lastApplyHouseTypeCode: '', // 原住房户型编号
        lastApplyHouseTypeName: '', // 原住房户型名称

        reason: '', // 申请事由
        executeDate: '', // 住/退/换房日期
        
        flowInfoDO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '申请人', prop: 'applicantName', type: 'search', disabled: () => this.getCommonDisabled },
        { label: '申请类型', prop: 'dormitoryType', disabled: () => this.getCommonDisabled, type: 'select', options: [] },
        { label: '性别', prop: 'gender', disabled: () => this.getCommonDisabled, type: 'select', options: genderOptions },
        { label: '身份证号', prop: 'idno', disabled: () => this.getCommonDisabled },
        { label: '入职日期', prop: 'hireDate', type: 'date', disabled: () => this.getCommonDisabled },
        { label: '申请住房', show: () => !this.isChackOutRoom, prop: 'applyHouseAddress', type: 'search', disabled: () => this.getCommonDisabled },
        { label: '房产编号', show: () => !this.isChackOutRoom, prop: 'applyHouseArchiveCode', disabled: true },
        { label: '申请户型', show: () => !this.isChackOutRoom, prop: 'applyHouseTypeId', type: 'select', options: [], disabled: () => this.getCommonDisabled  },
        { label: '原住房', show: () => !this.isHousingRoom, prop: 'lastApplyHouseAddress', disabled: true },
        { label: '原住房编号', show: () => !this.isHousingRoom, prop: 'lastHouseArchiveCode', disabled: true },
        { label: '原住房户型', show: () => !this.isHousingRoom, prop: 'lastApplyHouseTypeName', disabled: true}
      ],

      formBaseTextAreaList: [
        { label: '申请事由', prop: 'reason', disabled: () => this.getCommonDisabled },
      ],

      formReimList: [
        { label: '日期', prop: 'executeDate', disabled: true },
      ],
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    },

    getHouseDormitoryAppType() {

      if(!this.formBaseList[1].options.length || !this.form.dormitoryType) return ''

      const target = this.formBaseList[1].options.find(item => item.value === this.form.dormitoryType)

      return target.text
    },

    isHousingRoom() {
      return this.getHouseDormitoryAppType === '住房'
    },

    isChackOutRoom() {
      return this.getHouseDormitoryAppType === '退房'
    }
  },

  watch: {
    getHouseDormitoryAppType(val) {
      if(!val) return

      if(val === '退房') {
        this.form.applyHouseArchiveId = '' // 申请住房Id
        this.form.applyHouseAddress = '' // 申请住房
        this.form.applyHouseArchiveCode = '' // 房产编号
        this.form.applyHouseTypeId = '' // 申请户型Id
        this.form.applyHouseTypeCode = '' // 申请户型编号
        this.form.applyHouseTypeName = '' // 申请户型
      }

      this.formReimList[0].label = `${val}日期`
    },

    'form.applicant'(val, oval) {
      if(oval && val)  { 
        this.getNewInfo(val) 
      }
    }
  },

  created() {
    // 申请类型
    getDoc('065', res => (this.formBaseList[1].options = res))
    // 户型
    getDoc('066', res => (this.formBaseList[7].options = res))

    if(this.curType !== 'add') {
      getHouseDormitory(this.curPid).then(res => {
        // 申请人部门列表
        getDeptByUid(res.creator).then(depts => {
          this.formUserList[7].options = this.changeDeptProps(depts)
        })

        this.form = res

        // 单据状态(向上通知)
        const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
        this.$emit('status', status)
      })
    } else {
      // 申请人当前住房信息
      this.getNewInfo()
    }

    this.formUserList[0] = { label: '制单人', prop: 'creatorName', disabled: true }
  },

  methods: {
    moment,

    getNewInfo(uid) {
       // 申请人当前住房信息
       getNewInHouseInfoByUid(uid).then(res => {
        const [ firstHouseArchive = {} ] = res || []

        const { houseDormitoryAppInfo = {} } = firstHouseArchive

        const { 
          applyHouseArchiveId = '',
          applyHouseAddress = '', 
          applyHouseArchiveCode = '',
          applyHouseTypeId = '', 
          applyHouseTypeName = ''
        } = houseDormitoryAppInfo

        this.form.lastHouseArchiveId = applyHouseArchiveId // 住房Id
        this.form.lastApplyHouseAddress = applyHouseAddress // 住房地址
        this.form.lastHouseArchiveCode = applyHouseArchiveCode // 住房编号
        this.form.lastApplyHouseTypeId = applyHouseTypeId // 户型Id
        this.form.lastApplyHouseTypeName = applyHouseTypeName // 户型名称
      })
    },

    chooseHouseArchives(item) {
      this.form.applyHouseArchiveId = item.id // 申请住房Id
      this.form.applyHouseAddress = item.address // 申请住房
      this.form.applyHouseArchiveCode = item.houseCode // 房产编号
    },

    // 申请人
    onChooseUser({ username, uid }) {
      this.$set(this.form, 'applicant', uid)
      this.$set(this.form, 'applicantName', username)
    },

    onSelectChange(value, item) {
      if(item.prop === 'dormitoryType') {
        this.form.dormitoryTypeCode = this.formBaseList[1].options.find(item => item.value === value).code
      }
    },

    openModal(item) {
      switch (item.prop) {
        case 'applyHouseAddress':
          this.showModal = true
          break
        case 'applicantName':
          this.showUserModal = true
          break
        default:
          break
      }
    },

    filterData() {
      const submitData = cloneDeep(this.form)
      return submitData
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateHouseDormitory : saveHouseDormitory

      const submitData = this.filterData()

      if (this.verityAppForm(this.form.id ? false : 'save')) {
        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.replace({ name: 'houseDormitory' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {
        this.dataLoading = true
        submitHouseDormitory(submitData).then(() => {
          this.$message.success('提交成功')
          this.$router.replace({ name: 'houseDormitory' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该药连锁公司驻地宿舍申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delHouseDormitory(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'houseDormitory' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该药连锁公司驻地宿舍申请？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'YGSSSQ',
            this.form.flowInfoDO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
</style>

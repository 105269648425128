import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 药连锁房产档案
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getArchiveHouseList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `houseArchive/getHousePropertyArchiveList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新药连锁房产档案
 * @param {Object} data
 * @returns
 */
export function updateArchiveHouse(data) {
  return request({
    method: 'post',
    url: `houseArchive/updateHousePropertyArchive`,
    data
  })
}

/**
 * 保存药连锁房产档案
 * @param {Object} data
 * @returns
 */
export function saveArchiveHouse(data) {
  return request({
    method: 'post',
    url: `houseArchive/saveHousePropertyArchive`,
    data
  })
}

/**
 * 保存药连锁房产档案
 * @param {String} id
 * @returns
 */
export function delArchiveHouse(id) {
  return request({
    method: 'post',
    url: `houseArchive/delHousePropertyArchive/${id}`
  })
}

/**
 * 获取药连锁房产档案
 * @param {String} id
 * @returns
 */
export function getArchiveHouse(id) {
  return request({
    method: 'get',
    url: `houseArchive/${id}`
  })
}

/**
 * 导出房产档案
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/houseArchive/exportData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `房产档案`)
  })
}

/**
 * 导出房产租房人员
 * @param {Array} data 导出的条件
 */
export function getExportPersonExcel(data = []) {
  return request({
    url: `/houseArchive/exportRecordData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `房产住房人员`)
  })
}

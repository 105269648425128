// eslint-disable-next-line no-undef, no-useless-escape
export const digit = `this.value = this.value.match(/\\d+(\.\\d{0,2})?/) ? this.value.match(/\\d+(\.\\d{0,2})?/)[0] : ''`
export const number = `this.value = this.value.replace(/[^\\d]/g, '')`
// eslint-disable-next-line no-undef, no-useless-escape
export const mdigit = `this.value = this.value === '-'? '-' : this.value.match(/^-?\\d+(\.\\d{0,2})?/) ? this.value.match(/^-?\\d+(\.\\d{0,2})?/)[0] : ''`
// eslint-disable-next-line no-undef, no-useless-escape
export const fourDigit = `this.value = this.value.match(/\\d+(\.\\d{0,4})?/) ? this.value.match(/\\d+(\.\\d{0,4})?/)[0] : ''`
// eslint-disable-next-line no-undef, no-useless-escape
export const sevenDigit = `this.value = this.value.match(/\\d+(\.\\d{0,7})?/) ? this.value.match(/\\d+(\.\\d{0,7})?/)[0] : ''`

export const oninput = {
  digit,
  number,
  mdigit,
  fourDigit,
  sevenDigit
}

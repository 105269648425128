/* eslint-disable prettier/prettier */
export default {
  data() {
    return {
      fee: {
        trafficExpense: '', // 交通费单价
        trafficExpenseNum: '', // 交通费数量
        trafficExpenseTotal: '', // 交通费总价
        budgetReception: '', // 接送费单价
        budgetReceptionNum: '', // 接送费数量
        budgetReceptionTotal: '', // 接送费总价
        budgetAccommodation: '', // 住宿费单价
        budgetAccommodationNum: '', // 住宿费数量
        budgetAccommodationTotal: '', // 住宿费总价
        budgetMeals: '', // 餐费单价
        budgetMealsNum: '', // 餐费数量
        budgetMealsTotal: '', // 餐费总价
        meetingMaterialExpense: '', // 会务物料制作费单价
        meetingMaterialExpenseNum: '', // 会务物料制作费数量
        meetingMaterialExpenseTotal: '', // 会务物料制作费总价
        meetingMaterialExpenseDocs:'', //会务物料制作费附件
        budgetThirdparty: '', // 第三方会务人员费用单价
        budgetThirdpartyNum: '', // 第三方会务人员费用数量
        budgetThirdpartyTotal: '', // 第三方会务人员费用总价
        exhibitionExpense: '', // 展台布展单价
        exhibitionExpenseNum: '', // 展台布展数量
        exhibitionExpenseTotal: '', // 展台布展总价
        venueExpense: '', // 场地费单价
        venueExpenseNum: '', // 场地费数量
        venueExpenseTotal: '', // 场地费总价
        // accompanyExpense: '', // 陪同费用
        // budgetConsultTotal: '', // 讲者劳务费
        // onlineLiveBroadcastingFee: '', // 线上平台直播费用
        
        registerFee: '', // 注册费
        budgetServiceTotal: '', // 会务服务费、税费
        budgetCooperationTotal: '', // 第三方协办费用

        /** 废弃 */ 
        budgetBooth: '', // 展位(场地费)单价
        budgetBoothNum: '', // 展位(场地费)数量
        budgetMeetingroom: '', // 会议室(场地费) 单价
        budgetMeetingroomNum: '', // 会议室(场地费)数量
        budgetMake: '', // 制作费单价
        budgetMakeNum: '', // 制作费数量
        budgetDesign: '', // 设计费单价
        budgetDesignNum: '', // 设计费数量
        budgetFlight: '', // 飞机费单价
        budgetFlightNum: '', // 飞机费数量
        budgetTrain: '', // 高铁费单价
        budgetTrainNum: '', // 高铁费数量
        budgetBrandpreTotal: '', // 推广物料费
        otherFee: '', // 其他费用

        budgetTotal: 0, // 预算合计
        budgetApprovetotal: 0// 批准总预算
      },

      feeDatas: [
        { title: '交通费', price: 'trafficExpense', num: 'trafficExpenseNum', clacTotal: true, total: 'trafficExpenseTotal' },
        { title: '住宿费', price: 'budgetAccommodation', num: 'budgetAccommodationNum', clacTotal: true, total: 'budgetAccommodationTotal' },
        { title: '餐费', price: 'budgetMeals', num: 'budgetMealsNum', clacTotal: true, total: 'budgetMealsTotal' },
        { title: '会务物料制作费', price: 'meetingMaterialExpense', num: 'meetingMaterialExpenseNum', clacTotal: true, total: 'meetingMaterialExpenseTotal', upload: 'meetingMaterialExpenseDocs' },
        { title: '接送费', price: 'budgetReception', num: 'budgetReceptionNum', total: () => this.budgetReceptionTotal },
        { title: '展台布展', price: 'exhibitionExpense', num: 'exhibitionExpenseNum', total: () => this.exhibitionExpenseTotal },
        { title: '第三方会务人员费用', price: 'budgetThirdparty', num: 'budgetThirdpartyNum', total: () => this.budgetThirdpartyTotal },
        { title: '场地费', price: 'venueExpense', num: 'venueExpenseNum', total: () => this.venueExpenseTotal },
        { title: '注册费', price: '~', num: '~', total: 'registerFee' },
        { title: '会务服务费、税费', price: '~', num: '~', total: 'budgetServiceTotal' },
        { title: '第三方协办费用', price: '~', num: '~', notVerity: true, total: () => this.cooperateContractMny },
        { title: '线上平台直播费用', price: '~', num: '~', notVerity: true, total: () => this.onlineLiveBroadcastingFee },
        { title: '讲者劳务费', price: '~', num: '~', notVerity: true, total: () => this.budgetConsultTotal },
        { title: '陪同费用', price: '~', num: '~', notVerity: true, total: () => this.accompanyExpense },
        // 废弃
        { title: '展位(场地费)', price: 'budgetBooth', num: 'budgetBoothNum', total: () => this.budgetBoothTotal, notVerity: true, deprecated: true },
        { title: '会议室(场地费)', price: 'budgetMeetingroom', num: 'budgetMeetingroomNum', total: () => this.budgetMeetingroomTotal, notVerity: true, deprecated: true },
        { title: '制作费', price: 'budgetMake', num: 'budgetMakeNum', total: () => this.budgetMakeTotal, notVerity: true, deprecated: true },
        { title: '设计费', price: 'budgetDesign', num: 'budgetDesignNum', total: () => this.budgetDesignTotal, notVerity: true, deprecated: true },
        { title: '飞机费', price: 'budgetFlight', num: 'budgetFlightNum', total: () => this.budgetFlightTotal, notVerity: true, deprecated: true },
        { title: '高铁费', price: 'budgetTrain', num: 'budgetTrainNum', total: () => this.budgetTrainTotal, notVerity: true, deprecated: true },
        { title: '推广物料费', price: '~', num: '~', total: 'budgetBrandpreTotal', notVerity: true, deprecated: true },
        { title: '其他费用', price: '~', num: '~', total: 'otherFee', notVerity: true, deprecated: true }
      ],

      feeColumns: [
        { title: '项目', dataIndex: 'title' },
        { dataIndex: 'price', slots: { title: 'priceTitle' }, scopedSlots: { customRender: 'price' }  },
        { dataIndex: 'num', slots: { title: 'numTitle' }, scopedSlots: { customRender: 'num' }  },
        { dataIndex: 'total', slots: { title: 'totalTitle' }, scopedSlots: { customRender: 'total' } },
        { title: '附件上传', dataIndex: 'upload', scopedSlots: { customRender: 'upload' } }
      ]
    }
  },

  computed:{
    // 场地费
    venueExpenseTotal() {
      // const price = Number(this.fee.venueExpense || '0')
      // const num = Number(this.fee.venueExpenseNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.venueExpenseTotal || '0')
    },
    // 交通费
    trafficExpenseTotal(){
      // const price = Number(this.fee.trafficExpense || '0')
      // const num = Number(this.fee.trafficExpenseNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.trafficExpenseTotal || '0')
    },
    // 接送费
    budgetReceptionTotal(){
      // const price = Number(this.fee.budgetReception || '0')
      // const num = Number(this.fee.budgetReceptionNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.budgetReceptionTotal || '0')
    },
    // 住宿费
    budgetAccommodationTotal(){
      // const price = Number(this.fee.budgetAccommodation || '0')
      // const num = Number(this.fee.budgetAccommodationNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.budgetAccommodationTotal || '0')
    },
    // 餐费
    budgetMealsTotal(){
      // const price = Number(this.fee.budgetMeals || '0')
      // const num = Number(this.fee.budgetMealsNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.budgetMealsTotal || '0')
    },
    // 会务物料制作费
    meetingMaterialExpenseTotal(){
      // const price = Number(this.fee.meetingMaterialExpense || '0')
      // const num = Number(this.fee.meetingMaterialExpenseNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.meetingMaterialExpenseTotal || '0')
    },
    // 展台布展
    exhibitionExpenseTotal(){
      // const price = Number(this.fee.exhibitionExpense || '0')
      // const num = Number(this.fee.exhibitionExpenseNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.exhibitionExpenseTotal || '0')
    },
    // 第三方会务人员费用
    budgetThirdpartyTotal(){
      // const price = Number(this.fee.budgetThirdparty || '0')
      // const num = Number(this.fee.budgetThirdpartyNum || '0')
      // return this.$np.times(price, num)
      return Number(this.fee.budgetThirdpartyTotal || '0')
    },

    // 废弃字段
    // 展位(场地费)
    budgetBoothTotal(){
      const price = Number(this.fee.budgetBooth || '0')
      const num = Number(this.fee.budgetBoothNum || '0')
      return this.$np.times(price, num)
    },
    // 会议室(场地费)
    budgetMeetingroomTotal(){
      const price = Number(this.fee.budgetMeetingroom || '0')
      const num = Number(this.fee.budgetMeetingroomNum || '0')
      return this.$np.times(price, num)
    },
    // 制作费
    budgetMakeTotal(){
      const price = Number(this.fee.budgetMake || '0')
      const num = Number(this.fee.budgetMakeNum || '0')
      return this.$np.times(price, num)
    },
    // 设计费
    budgetDesignTotal(){
      const price = Number(this.fee.budgetDesign || '0')
      const num = Number(this.fee.budgetDesignNum || '0')
      return this.$np.times(price, num)
    },
    // 飞机费
    budgetFlightTotal(){
      const price = Number(this.fee.budgetFlight || '0')
      const num = Number(this.fee.budgetFlightNum || '0')
      return this.$np.times(price, num)
    },
    // 高铁费
    budgetTrainTotal(){
      const price = Number(this.fee.budgetTrain || '0')
      const num = Number(this.fee.budgetTrainNum || '0')
      return this.$np.times(price, num)
    },

    /** 特殊 */
    // 公对公预付金额(活动信息 模块)(不参与费用计算合计，只在显示在: 预算金额)
    budgetPreCost() {
      return this.activity.budgetPreCost || '0'
    },
    // 讲者劳务费(讲者信息 模块)
    budgetConsultTotal() {
      return this.speaker.budgetConsultTotal || '0'
    },
    // 线上平台直播费用(活动信息 模块)
    onlineLiveBroadcastingFee() {
      return this.activity.onlineLiveBroadcastingFee || '0'
    },
    // 陪同费用(陪同信息 模块)
    accompanyExpense() {
      return this.accompany.accompanyExpense || '0'
    },
    // 协办合同金额(活动信息 模块)
    cooperateContractMny() {
      return this.activity.cooperateContractMny || '0'
    }
  },

  watch: {
    trafficExpenseTotal: function() {
      if(this.fee.trafficExpenseNum == 0 || !this.fee.trafficExpenseNum) {
        this.fee.trafficExpense = 0
      } else {
        this.fee.trafficExpense = this.$np.round(
          this.$np.divide(
            this.fee.trafficExpenseTotal || '0', 
            this.fee.trafficExpenseNum || '0'
          ), 2)
      }
      this.calcFeeTotal() 
    },
    budgetAccommodationTotal: function() {
      if(this.fee.budgetAccommodationNum == 0 || !this.fee.budgetAccommodationNum) {
        this.fee.budgetAccommodation = 0
      } else {
        this.fee.budgetAccommodation = this.$np.round(
          this.$np.divide(
            this.fee.budgetAccommodationTotal || '0',
            this.fee.budgetAccommodationNum || '0'
          ), 2)
      }
      this.calcFeeTotal() 
    },
    budgetMealsTotal: function() {
      if(this.fee.budgetMealsNum == 0 || !this.fee.budgetMealsNum) {
        this.fee.budgetMeals = 0
      } else {
        this.fee.budgetMeals = this.$np.round(
          this.$np.divide(
            this.fee.budgetMealsTotal || '0',
            this.fee.budgetMealsNum || '0'
          ), 2)
      }
      this.calcFeeTotal() 
    },
    meetingMaterialExpenseTotal: function() {
      if(this.fee.meetingMaterialExpenseNum == 0 || !this.fee.meetingMaterialExpenseNum) {
        this.fee.meetingMaterialExpense = 0
      } else {
        this.fee.meetingMaterialExpense = this.$np.round(
          this.$np.divide(
            this.fee.meetingMaterialExpenseTotal || '0', 
            this.fee.meetingMaterialExpenseNum || '0'
          ), 2)
      }
      this.calcFeeTotal() 
    },

    'fee.budgetReception': function() { 
      const price = Number(this.fee.budgetReception || '0')
      const num = Number(this.fee.budgetReceptionNum || '0')
      this.fee.budgetReceptionTotal = this.$np.times(price, num)
      this.calcFeeTotal() 
    }, // 接待费单价
    'fee.budgetReceptionNum': function() { 
      const price = Number(this.fee.budgetReception || '0')
      const num = Number(this.fee.budgetReceptionNum || '0')
      this.fee.budgetReceptionTotal = this.$np.times(price, num)
      this.calcFeeTotal() 
    }, // 接待费数量

    'fee.exhibitionExpense': function() {
      const price = Number(this.fee.exhibitionExpense || '0')
      const num = Number(this.fee.exhibitionExpenseNum || '0')
      this.exhibitionExpenseTotal = this.$np.times(price, num)
      this.calcFeeTotal() 
    }, // 展台费单价
    'fee.exhibitionExpenseNum': function() {
      const price = Number(this.fee.exhibitionExpense || '0')
      const num = Number(this.fee.exhibitionExpenseNum || '0')
      this.fee.exhibitionExpenseTotal = this.$np.times(price, num)
      this.calcFeeTotal()
    }, // 展台费数量

    'fee.venueExpense': function() {
      const price = Number(this.fee.venueExpense || '0')
      const num = Number(this.fee.venueExpenseNum || '0')
      this.fee.venueExpenseTotal = this.$np.times(price, num)
      this.calcFeeTotal()
    }, // 场地费单价
    'fee.venueExpenseNum': function() {
      const price = Number(this.fee.venueExpense || '0')
      const num = Number(this.fee.venueExpenseNum || '0')
      this.fee.venueExpenseTotal = this.$np.times(price, num)
      this.calcFeeTotal()
    }, // 场地费数量

    'fee.budgetThirdparty': function() {
      const price = Number(this.fee.budgetThirdparty || '0')
      const num = Number(this.fee.budgetThirdpartyNum || '0')
      this.fee.budgetThirdpartyTotal = this.$np.times(price, num)
      this.calcFeeTotal()
    }, // 第三方费用单价
    'fee.budgetThirdpartyNum': function() {
      const price = Number(this.fee.budgetThirdparty || '0')
      const num = Number(this.fee.budgetThirdpartyNum || '0')
      this.fee.budgetThirdpartyTotal = this.$np.times(price, num)
      this.calcFeeTotal()
    }, // 第三方费用数量

    budgetReceptionTotal: function() { this.calcFeeTotal() }, // 接待费
    exhibitionExpenseTotal: function() { this.calcFeeTotal() }, // 展台费 
    budgetThirdpartyTotal: function(){ this.calcFeeTotal() }, // 第三方费用
    venueExpenseTotal: function() { this.calcFeeTotal() }, // 场地费
    budgetConsultTotal: function() { this.calcFeeTotal() }, // 讲者劳务费
    onlineLiveBroadcastingFee: function() { this.calcFeeTotal() }, // 线上平台直播费用
    accompanyExpense: function() { this.calcFeeTotal() }, // 陪同费用
    'fee.registerFee': function() { this.calcFeeTotal() }, // 注册费
    'fee.budgetServiceTotal': function() { this.calcFeeTotal() }, // 会务服务费、税费

    'fee.trafficExpenseNum': function() {
      if(this.fee.trafficExpenseNum == 0 || !this.fee.trafficExpenseNum) {
        this.fee.trafficExpense = 0
        return
      }
      this.fee.trafficExpense = this.$np.round(
        this.$np.divide(
          this.fee.trafficExpenseTotal || '0', 
          this.fee.trafficExpenseNum || '0'
        ), 2)
    },
    'fee.budgetAccommodationNum': function() {
      if(this.fee.budgetAccommodationNum == 0 || !this.fee.budgetAccommodationNum) {
        this.fee.budgetAccommodation = 0
        return
      }
      this.fee.budgetAccommodation = this.$np.round(
        this.$np.divide(
          this.fee.budgetAccommodationTotal || '0',
          this.fee.budgetAccommodationNum || '0'
        ), 2)
    },
    'fee.budgetMealsNum': function() {
      if(this.fee.budgetMealsNum == 0 || !this.fee.budgetMealsNum) {
        this.fee.budgetMeals = 0
        return
      }
      this.fee.budgetMeals = this.$np.round(
        this.$np.divide(
          this.fee.budgetMealsTotal || '0',
          this.fee.budgetMealsNum || '0'
        ), 2)
    },
    'fee.meetingMaterialExpenseNum': function() {
      if(this.fee.meetingMaterialExpenseNum == 0 || !this.fee.meetingMaterialExpenseNum) {
        this.fee.meetingMaterialExpense = 0
        return
      }
      this.fee.meetingMaterialExpense = this.$np.round(
        this.$np.divide(
          this.fee.meetingMaterialExpenseTotal || '0', 
          this.fee.meetingMaterialExpenseNum || '0'
        ), 2)
    },

    // 第三方协办费用
    cooperateContractMny: function(nval){
      this.fee.budgetCooperationTotal = nval
      this.calcFeeTotal()
    }
  },

  methods: {
    // 预算金额合计
    calcFeeTotal() {
      this.fee.budgetTotal = this.$np.plus(
        Number(this.venueExpenseTotal || '0'),
        Number(this.trafficExpenseTotal || '0'),
        Number(this.budgetReceptionTotal || '0'),
        Number(this.budgetAccommodationTotal || '0'),
        Number(this.budgetMealsTotal || '0'),
        Number(this.meetingMaterialExpenseTotal || '0'),
        Number(this.exhibitionExpenseTotal || '0'),
        Number(this.budgetThirdpartyTotal || '0'),
        Number(this.budgetConsultTotal || '0'),
        Number(this.onlineLiveBroadcastingFee || '0'),
        Number(this.accompanyExpense || '0'),
        Number(this.fee.registerFee || '0'),
        Number(this.fee.budgetServiceTotal || '0'),
        Number(this.fee.budgetCooperationTotal || '0'),
      )
    }
  }
}

<template>
  <a-layout-header class="h-16 px-4 bg-white">
    <a-icon
      class="trigger cursor-pointer"
      :type="collapsed ? 'menu-unfold' : 'menu-fold'"
      :title="collapsed ? '展开菜单' : '折叠菜单'"
      :style="{ fontSize: '18px' }"
      @click="$emit('toggle')"
    />
    <!-- <div class="bread-crumb">
      <a-breadcrumb separator="/">
        <template v-for="{ name, meta } in breadcrumbList">
          <a-breadcrumb-item
            :key="name"
            v-if="!!meta.title"
            class="breadcrumb-item"
          >
            {{ meta.title }}
          </a-breadcrumb-item>
        </template>
      </a-breadcrumb>
    </div> -->
    <div class="float-right mr-2 flex items-center" v-if="!showAvatar">
      <Notice ref="notice"></Notice>

      <icon-font class="mr-3 text-3xl" type="icon_img_avatars_top" />
      <a-dropdown>
        <a>
          {{ username }}
          <a-icon type="down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a class="text-xs" @click="mdPwd()">
              <a-icon class="mr-2" type="safety" />
              修改密码
            </a>
          </a-menu-item>
          <a-menu-item>
            <a class="text-xs" @click="rlogout()">
              <a-icon class="mr-2" type="poweroff" />
              注销登录
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </a-layout-header>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { rlogout, mdPwd } from '@/utils'
import Notice from './Notice'
export default {
  name: 'GlobalHeader',
  components: { Notice },
  props: {
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      noticeMsg: '',
      showNoticeMsg: false,
      breadcrumbList: [],
      kscoket: null,

      reconnectTime: 3, //重连的次数
      reconnectNum: 3000 //每隔三秒重连一次
    }
  },
  computed: {
    ...mapGetters(['username']),
    showAvatar() {
      return store.getters['protal']
    },

    getResultListLength() {
      return this.$store.getters.resultList.length
    },

    getAnuceListLength() {
      return this.$store.getters.anuceList.length
    }
  },

  watch: {
    $route() {
      this.getBreadcrumb()
    },

    getResultListLength(nval, oval) {
      if (nval && oval > nval) return
      this.showNoticePopover('消息')
    },

    getAnuceListLength(nval, oval) {
      if (nval && oval > nval) return
      this.showNoticePopover('公告')
    }
  },

  created() {
    this.getBreadcrumb()
    const userCode = this.$store.getters.userCode
    const webSocketUrl =
      process.env.VUE_APP_MODE === 'production'
        ? 'ws://crm.dyg.com.cn'
        : 'wss://crmtest.dyg.com.cn:7090'
    const connectUrl = `${webSocketUrl}/websocket/msg/${userCode}`
    this.$connect(connectUrl, {
      format: 'json',
      reconnection: true, // (Boolean)是否自动重连，默认false
      reconnectionAttempts: 5, // 重连次数
      reconnectionDelay: 3000 // 再次重连等待时常(1000)
    })
    this.initSocket(this.$socket)
    this.$store.commit('socket/SET_SOCKET', this.$socket)
  },

  destroyed() {
    if (this.kscoket) {
      this.kscoket.close()
      this.$store.commit('socket/SET_SOCKET', null)
    }
  },

  methods: {
    rlogout,
    mdPwd,

    // 初始化socket
    initSocket(socket) {
      socket.onmessage = this.websocketonmessage
      socket.onopen = this.websocketonopen
    },

    // 链接ws服务器
    websocketonopen() {},

    // 数据接收
    websocketonmessage(e) {
      const _this = this
      try {
        const message = JSON.parse(e.data)
        const { data, module, type, heartInfo } = message

        _this.kscoket = e.target

        if (heartInfo) {
          e.target.send(`{ "heartInfo": "reply" }`)
          return
        }

        if (module === 'flowdirect' && !type) {
          _this.$store.commit('notice/ADD_RESULT_ITEM', data)
        }

        if (type === 'unreadMsgNum' && module === 'flowdirect') {
          _this.$store.commit('notice/SET_UNREADRESULT', data.unreadMsgNum)
        }

        if (module === 'anuce' && type === 'newAnuce') {
          // 欺骗操作（显示气泡卡片）
          _this.$store.commit('anuce/ADD_ANUCE_ITEM', data)
          setTimeout(() => {
            _this.$store.commit('anuce/CLEAR_ANUCE_LIST')
          }, 0)

          this.$refs.notice.refreshAunce()
        }

        if (module === 'anuce' && type === 'unreadAnuceNum') {
          _this.$store.commit('anuce/SET_UNREADANUCE', data.unreadAnuceNum)
        }
      } catch {
        _this.$message.error('socket数据格式错误')
      }
    },

    getBreadcrumb() {
      const [, ...list] = this.$route.matched
      this.breadcrumbList = list
    },

    showNoticePopover(str) {
      if (this.showNoticeMsg || this.$refs.notice.visible) return

      this.showNoticeMsg = true
      this.noticeMsg = `您有一个${str}`
      setTimeout(() => {
        this.showNoticeMsg = false
        this.noticeMsg = ''
      }, 3000)
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  padding: 10px 20px;
  font-size: 14px;

  font-weight: 600;
  color: #97a8be;
  display: inline-block;
}
/deep/ .ant-breadcrumb-separator {
  font-weight: 700;
}
/deep/.ant-breadcrumb .breadcrumb-item:last-child .ant-breadcrumb-separator {
  display: none !important;
}
/deep/.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: inline-block;
}
</style>

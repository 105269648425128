const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `appTypeName` },
      { label: '申请状态', value: `appStatus` },
      { label: '姓名', value: `name` },
      { label: '医生编码', value: `doctorCode` },
      { label: '性别', value: `gender` },
      { label: '单位名称', value: `departmentName` },
      { label: '单位编码', value: `departmentCode` },
      { label: '科室', value: `section` },
      { label: '学术头衔', value: `academicTitle` },
      { label: '执业医师证书编码', value: `certificationCode` },
      { label: '医生专长', value: `doctorExpertise` },
      { label: '医生状态', value: `doctorStatus` },
      { label: '相关产品/治疗邻域', value: `relativeArea` },
      { label: '行政职务', value: `adminPost` },
      { label: '职称', value: `profTitle` },
      { label: '产品线', value: `productLineName` }
    ]
  },
  {
    title: '个人信息',
    active: 'SELF',
    children: [
      { label: '证件类型', value: `idTypeName` },
      { label: '证件号', value: `idCode` },
      { label: '银行卡号', value: `bankCardNumber` },
      { label: '开户支行', value: `bankName` },
      { label: '开户人姓名', value: `accountOwner` }
    ]
  },
  {
    title: '级别评定',
    active: 'LEVEL',
    children: [
      { label: '讲者职称', value: `speakerTitleName` },
      { label: '单位级别', value: `departmentLevelName` },
      { label: '学历', value: `educationalBackgroundName` },
      { label: '学会任职', value: `academicPostName` },
      { label: '核心期刊发表', value: `publication` },
      { label: '咨询费', value: `consultingFee` },
      { label: '讲者级别', value: `speakerLevelName` }
    ]
  },
  {
    title: '讲者状态',
    active: 'STATUS',
    children: [
      { label: '讲者状态', value: `speakerStatusName` },
      // { label: '是否黑名单', value: `isblacklist` },
      // { label: '失效日期', value: `expirationDate` },
      { label: '备注', value: `remarks` },
      // { label: '生效日期', value: `effectiveDate` },
      { label: '创建时间', value: `creationtime` },
      { label: '创建人', value: `creatorName` },
      { label: '修改时间', value: `modifytime` },
      { label: '修改人', value: `modifierName` },
      { label: '资质信息网', value: `qualificationInfo` }
    ]
  }
]
const TABLE = [
  {
    title: '差异信息',
    active: 'DIFF',
    dataList: 'differDTOs',
    columns: [
      { title: '字段名称', dataIndex: 'fieldName' },
      { title: '数据变更前', dataIndex: 'beforeValue' },
      { title: '数据变更后', dataIndex: 'afterValue' }
    ]
  }
]

export { BASE, TABLE }

import Users from '@/views/user'

export default {
  path: 'user',
  component: Users,
  redirect: 'userManage',
  name: 'user',
  children: [
    {
      path: 'userManage',
      name: 'userManage',
      component: () =>
        import(/* webpackChunkName: 'exception' */ '@/views/user/users'),
      redirect: 'userManage/userIndex',
      meta: { title: '用户管理' },
      children: [
        {
          path: 'userIndex',
          name: 'userIndex',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/users/UserIndex'
            ),
          meta: { title: '用户列表', menuKey: 'userManage' }
        },
        {
          path: 'newUser',
          name: 'newUser',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/users/NewUser'
            ),
          meta: { title: '用户信息', menuKey: 'userManage' }
        }
      ]
    },
    {
      path: 'roleManage',
      name: 'roleManage',
      component: () =>
        import(/* webpackChunkName: 'exception' */ '@/views/user/roles'),
      redirect: 'roleManage/roleIndex',
      meta: { title: '角色管理' },
      children: [
        {
          path: 'roleIndex',
          name: 'roleIndex',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/roles/RoleIndex'
            ),
          meta: { title: '角色列表', menuKey: 'roleManage' }
        },
        {
          path: 'newRole',
          name: 'newRole',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/roles/NewRole'
            ),
          meta: { title: '角色信息', menuKey: 'roleManage' }
        }
      ]
    },
    {
      path: 'postManage',
      name: 'postManage',
      component: () =>
        import(/* webpackChunkName: 'exception' */ '@/views/user/post'),
      redirect: 'postManage/postIndex',
      meta: { title: '岗位管理' },
      children: [
        {
          path: 'postIndex',
          name: 'postIndex',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/post/PostIndex'
            ),
          meta: { title: '岗位信息', menuKey: 'postManage' }
        },
        {
          path: 'postDetail',
          name: 'postDetail',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/post/PostDetail'
            ),
          meta: { title: '岗位详情', menuKey: 'postManage' }
        },
        {
          path: 'buildPost',
          name: 'buildPost',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/post/BuildPost'
            ),
          meta: { title: '新建岗位', menuKey: 'postManage' }
        }
      ]
    },
    {
      path: 'groupManage',
      name: 'groupManage',
      component: () =>
        import(/* webpackChunkName: 'exception' */ '@/views/user/depts'),
      redirect: 'groupManage/deptTabs',
      meta: { title: '组织管理' },
      children: [
        {
          path: 'deptTabs',
          name: 'deptTabs',
          component: () =>
            import(
              /* webpackChunkName: 'exception' */ '@/views/user/depts/DeptTabs'
            ),
          meta: { title: '组织信息', menuKey: 'groupManage' }
        }
      ]
    }
  ]
}

export default {
  methods: {
    verityAppForm(isSubmit) {
      if (isSubmit === 'save') return true

      const canVerityFormItem = [
        ...(this.formUserList || []),
        ...(this.formBaseList || []),
        ...(this.formBaseTextAreaList || []),
        ...(this.formTestList || []),
        ...(this.formTestTextAreaList || [])
      ].filter(item => {
        if (item.required === true) return true
        else if (item.required === false) return false

        if (typeof item.disabled === 'boolean') {
          return !item.disabled
        } else {
          return !item.disabled()
        }
      })

      // 用户信息、核销基本信息、送检信息
      for (const formItem of canVerityFormItem) {
        if (formItem.show && !formItem.show()) continue

        if (!this.form[formItem.prop] && this.form[formItem.prop] != '0') {
          this.$message.error(`${formItem.label}不能为空`)
          return false
        }
      }

      // 报销凭证附件
      if (this.formTestFileList && this.formTestFileList.length > 0) {
        for (const formFileItem of this.formTestFileList) {
          if (formFileItem.notVerity) continue

          if (
            !this.form[formFileItem.prop] ||
            this.form[formFileItem.prop] === '[]'
          ) {
            this.$message.error(`请上传${formFileItem.label}附件`)
            return false
          }
        }
      }

      return true
    }
  }
}
